import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from './AppBaseService';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AppBaseServiceProvider {
  serviceName = 'User';
  currentVersion = '1.0';
}
