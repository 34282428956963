import { Injectable } from '@angular/core';
import { Notes } from '../entity/Notes';
import { getRepository, SelectQueryBuilder } from 'typeorm/browser';
import { NoteCommentEntityManagerService } from './note-comment-entity-manager.service';
import { NoteLessonlearntEntityManagerService } from './note-lessonlearnt-entity-manager.service';
import { NoteTagEntityManagerService } from './note-tag-entity-manager.service';
import { Hierarchy } from '../entity/Hierarchy';
import { NoteComment } from '../entity/NoteComment';
import { NoteLessonLearnt } from '../entity/LessonLearnt';
import { Filter, DurationType } from '../InternalTypes';
import { LoadingService } from 'src/services/loading.service';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { NoteTag } from '../entity/NoteTag';
import * as moment from 'moment';
import { NoteAttachmentEntityManagerService } from './note-attachment-entity-manager.service';
import { Attachment } from '../entity/Attachment';

@Injectable({
  providedIn: 'root',
})
export class NoteEntityManagerService extends AppBaseEntityManager {
  protected _entityType = Notes;

  constructor(
    private noteCommentEntityManager: NoteCommentEntityManagerService,
    private noteLessonLearntEntityManager: NoteLessonlearntEntityManagerService,
    private noteTagEntityManager: NoteTagEntityManagerService,
    private loading: LoadingService,
    private noteAttachmentEntityManager: NoteAttachmentEntityManagerService
  ) {
    super();
  }

  getNotesForHierarchy(hierarchy: Hierarchy, user?: string): Promise<Notes[]> {
    return new Promise<Notes[]>(async (resolve, reject) => {
      if (hierarchy) {
        const noteRepository = getRepository('notes');
        const noteRueryBuilder: SelectQueryBuilder<Notes> = noteRepository
          .createQueryBuilder('notes')
          .where(
            'notes.ParentId = :parentId and notes.ParentEntity = :parentEntity and notes.IsDeleted != 1',
            {
              parentId: hierarchy.ForeignId,
              parentEntity: hierarchy.ForeignEntity,
            }
          ) as SelectQueryBuilder<Notes>;

        if (user) {
          noteRueryBuilder.andWhere(
            'notes.CreatedByUPN = :user COLLATE NOCASE',
            { user }
          );
        }
        const notes = await noteRueryBuilder
          .orderBy('notes.UpdatedDate', 'DESC')
          .getMany();
        for (const note of notes) {
          note.Comments =
            await this.noteCommentEntityManager.getCommentsForNote(note.Id);
          note.Tags = await this.noteTagEntityManager.getTagsForNote(note.Id);
        }
        resolve(notes);
      } else {
        console.log('only notes condition where resolve not working');
      }
    });
  }

  getNotesCountForHierarchy(
    hierarchy: Hierarchy,
    user?: string
  ): Promise<number> {
    if (hierarchy) {
      const noteRepository = getRepository('notes');
      const noteQueryBuilder: SelectQueryBuilder<Notes> = noteRepository
        .createQueryBuilder('notes')
        .where(
          'notes.ParentId = :parentId and notes.ParentEntity = :parentEntity and notes.IsDeleted != 1',
          {
            parentId: hierarchy.ForeignId,
            parentEntity: hierarchy.ForeignEntity,
          }
        ) as SelectQueryBuilder<Notes>;

      if (user) {
        noteQueryBuilder.andWhere('notes.CreatedByUPN = :user COLLATE NOCASE', {
          user,
        });
      }
      return noteQueryBuilder.getCount();
    } else {
      return null;
    }
  }

  createNote(note: Notes): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await note.save();
        await this.noteCommentEntityManager.deleteCommentsForNote(note.Id);
        for (let index = 0; index < note.Comments.length; index++) {
          if (
            note.Comments[index].CreatedByUPN === null ||
            note.Comments[index].CreatedByUPN === undefined
          ) {
            note.Comments[index].CreatedByUPN = note.CreatedByUPN;
            note.Comments[index].UpdatedByUPN = note.UpdatedByUPN;
            note.Comments[index] = new NoteComment(note.Comments[index]);
          }
        }
        await this.noteCommentEntityManager.saveComments(note.Comments);
        await this.noteLessonLearntEntityManager.deletelessonlearntForNote(
          note.Id
        );
        await this.noteLessonLearntEntityManager.saveLessonLearnt(
          note.Lessonlearnt
        );
        const tagsForNote = [];
        for (const t of note.Tags) {
          const tag = new NoteTag();
          tag.Tag = t;
          tag.NoteId = note.Id;
          tag.CreatedByUPN = note.CreatedByUPN;
          tag.UpdatedByUPN = note.UpdatedByUPN;
          tag.IsDeleted = false;
          tagsForNote.push(tag);
        }
        await this.noteTagEntityManager.deleteTagsForNote(note.Id);
        await this.noteTagEntityManager.saveTags(tagsForNote);
        await this.noteAttachmentEntityManager.deleteAttachmentsForNote(
          note.Id
        );
        await this.noteAttachmentEntityManager.saveAttachments(
          note.Attachments
        );
        resolve(); // since no method is called inside it
      } catch (error) {
        reject(error);
      }
    });
  }

  saveNotes(notes: Notes[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.bulkInsert2(notes)
        .then(async (_) => {
          let comments = [];
          let tags = [];
          let attachments = [];
          let lessonlearnt = [];
          const NoteIdArray = notes.map((note) => note.Id);
          for (const note of notes) {
            if (note.Comments) {
              const commentsForNote = note.Comments.map((val) => {
                return new NoteComment(val);
              });
              comments = comments.concat(commentsForNote);
            }

            if (note.Lessonlearnt) {
              const LLforNote = note.Lessonlearnt.map((val) => {
                return new NoteLessonLearnt(val);
              });
              lessonlearnt = lessonlearnt.concat(LLforNote);
            }

            if (note.Tags) {
              const tagsForNote = note.Tags.map((val) => {
                const tag = new NoteTag();
                tag.Tag = val;
                tag.NoteId = note.Id;
                return tag;
              });
              tags = tags.concat(tagsForNote);
            }

            if (note.Attachments) {
              const attachmentForNote = note.Attachments.map((val) => {
                delete val['fileArray'];
                return new Attachment(val);
              });

              attachments = attachments.concat(attachmentForNote);
            }
          }
          await this.noteCommentEntityManager.deleteCommentsForNotes(
            NoteIdArray
          );
          await this.noteCommentEntityManager.bulkInsert2(comments);

          await this.noteLessonLearntEntityManager.deletelessonlearntForNotes(
            NoteIdArray
          );
          await this.noteLessonLearntEntityManager.bulkInsert2(lessonlearnt);

          await this.noteTagEntityManager.deleteTagsForNotes(NoteIdArray);
          await this.noteTagEntityManager.bulkInsert2(tags);

          await this.noteAttachmentEntityManager.deleteAttachmentsForNotes(
            NoteIdArray
          );
          await this.noteAttachmentEntityManager.bulkInsert2(attachments);
          resolve(); // since no method is called inside it
        })
        .catch((error) => reject(error));
    });
  }

  getFilteredNotes(filter: Filter, eventId?: number): Promise<Notes[]> {
    return new Promise<Notes[]>(async (resolve, reject) => {
      try {
        this.loading.present();
        const notesRepository = getRepository('notes');
        let notesQuery = notesRepository.createQueryBuilder('notes');
        const hasTags = filter?.tags?.length > 0;

        if (hasTags) {
          const tagStr = filter.tags.map((tag) => `'${tag}'`).join(',');
          notesQuery
            .leftJoin(NoteTag, 'noteTag', 'notes.Id = noteTag.NoteId')
            .where(`noteTag.Tag in (${tagStr})`);
        }

        if (eventId) {
          if (hasTags) {
            notesQuery.andWhere('notes.eventId = :eventId', {
              eventId,
            });
          } else {
            notesQuery.where('notes.eventId = :eventId', { eventId });
          }
        }

        notesQuery = this._getNotesFilterQuery(filter, notesQuery);
        notesQuery.orderBy('notes.UpdatedDate', 'DESC');
        const notes = await notesQuery
          .getMany()
          .finally(() => this.loading.dismiss());
        resolve(notes as Notes[]);
      } catch (e) {
        reject(e);
      }
    });
  }

  private _getNotesFilterQuery(filter: Filter, notesQuery: SelectQueryBuilder<unknown>): SelectQueryBuilder<unknown> {
    if (filter?.hierarchy?.length > 0) {
      const hierarchyIds = [];
      for (const h of filter?.hierarchy) {
        hierarchyIds.push(h.ForeignId);
      }
      notesQuery.andWhere(`notes.ParentId IN (${hierarchyIds.join(',')})`);
    }

    if (filter?.authors?.length > 0) {
      const authorStr = filter?.authors
        .map((author) => `'${author.UPN.toUpperCase()}'`)
        .join(',');
      notesQuery.andWhere(
        `(upper(notes.CreatedByUPN) IN (${authorStr}) OR upper(notes.UpdatedByUPN) IN (${authorStr}))`
      );
    }

    if (filter?.roles?.length > 0) {
      let roles = filter?.roles.join(`','`);
      roles = `'` + roles + `'`;
      notesQuery.andWhere(`notes.Role IN (${roles})`);
    }

    // TODO: Modify Date comparisons
    if (filter?.shiftStartDate) {
      let date = new Date(filter?.shiftStartDate);
      date = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      );
      date.setUTCHours(0);
      const dateStr = date.toISOString();
      notesQuery.andWhere('notes.ShiftDate >= date(:startDate)', {
        startDate: dateStr,
      });
    }
    if (filter?.shiftEndDate) {
      let date = new Date(filter?.shiftEndDate);
      date = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      );
      date.setUTCHours(0);
      const dateStr = date.toISOString();
      notesQuery.andWhere('notes.ShiftDate <= :endDate', {
        endDate: dateStr,
      });
    }
    if (filter?.shiftNames?.length > 0) {
      notesQuery.andWhere(
        // tslint:disable-next-line:quotemark
        `notes.ShiftName IN ('${filter?.shiftNames.join("','")}')`
      );
    }
    // Review time zone data are storage on UTC Time
    // get time on house
    const tm = moment(new Date()).utcOffset() / 60;
    if (filter?.duration === DurationType.halfDay) {
      // tslint:disable-next-line:quotemark
      notesQuery.andWhere(
        "notes.CreatedDate >= date('now', '" + (-12 - tm) + " hour')"
      );
    } else if (filter?.duration === DurationType.fullDay) {
      // tslint:disable-next-line:quotemark
      notesQuery.andWhere(
        "notes.CreatedDate >= date('now', '" + (-24 - tm) + " hour')"
      );
    } else {
      if (filter?.createdDateFrom) {
        notesQuery.andWhere('notes.CreatedDate >= :fromDate', {
          fromDate: new Date(filter?.createdDateFrom).toISOString(),
        });
      }
      if (filter?.createdDateTo) {
        notesQuery.andWhere('notes.CreatedDate <= :toDate', {
          toDate: new Date(filter?.createdDateTo).toISOString(),
        });
      }
    }

    return notesQuery; 
  }

  getAllOfflineNotes(): Promise<Notes[]> {
    return new Promise<Notes[]>((resolve, reject) => {
      try {
        const noteRepository = getRepository('notes');
        const noteQueryBuilder: SelectQueryBuilder<Notes> = noteRepository
          .createQueryBuilder('notes')
          .where(
            'IsUploaded != 1 and notes.IsDeleted != 1'
          ) as SelectQueryBuilder<Notes>;
        resolve(noteQueryBuilder.getMany());
      } catch (error) {
        reject(error);
      }
    });
  }

  getOfflineNotes(provider: string, eventId: number): Promise<Notes[]> {
    return new Promise<Notes[]>((resolve, reject) => {
      try {
        const noteRepository = getRepository('notes');
        const noteQueryBuilder: SelectQueryBuilder<Notes> = noteRepository
          .createQueryBuilder('notes')
          .where(
            'notes.EventId = :eventId and IsUploaded != 1 and notes.IsDeleted != 1',
            {
              eventId,
            }
          ) as SelectQueryBuilder<Notes>;
        resolve(noteQueryBuilder.getMany());
      } catch (error) {
        reject(error);
      }
    });
  }

  getNote(noteId: string): Promise<Notes> {
    return new Promise<Notes>((resolve, reject) => {
      try {
        const noteQueryBuilder: SelectQueryBuilder<Notes> = getRepository(
          'notes'
        )
          .createQueryBuilder('notes')
          .where('notes.Id = :noteId', {
            noteId,
          }) as SelectQueryBuilder<Notes>;
        resolve(noteQueryBuilder.getOne());
      } catch (error) {
        reject(error);
      }
    });
  }
}
