import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from '../entityManager/AppBaseEntityManager';
import { LoadingService } from 'src/services/loading.service';
import { getRepository } from 'typeorm/browser';

import { Event } from '../model/Event';

@Injectable({
  providedIn: 'root'
})
export class EventEntityManagerService extends AppBaseEntityManager {
  protected _entityType = Event;

  constructor(private loading: LoadingService) {
    super();
  }

  getEvents(obs: string): Promise<Event[]> {
    return new Promise<Event[]>(async (resolve, reject) => {
      const eventRepository = getRepository(Event);
      const events = await eventRepository
        .createQueryBuilder('hierarchy')
        // tslint:disable-next-line:quotemark
        .where(`ForeignParentEntity='${obs}' and isDeleted!=1`)
        .getMany();
      resolve(events);
    });
  }

  saveEvents(events: Event[], obs: string): Promise<void> {
    events.forEach(event => (event.ForeignParentEntity = obs));
    return this.bulkInsert2(events);
  }
}
