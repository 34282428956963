import { Entity, Column, PrimaryGeneratedColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('synclog')
export class SyncLog extends AppBaseEntity {
  @PrimaryGeneratedColumn({ type: 'int' })
  Id: number;

  @Column({ type: 'text' })
  Entity: string;

  @Column({ type: 'text' })
  EventId: number;

  @Column({ nullable: true, type: 'text' })
  Provider: string;

  @Column({ nullable: true, type: 'text' })
  LastUploadTime: string;

  @Column({ nullable: true, type: 'text' })
  LastDownloadTime: string;
}
