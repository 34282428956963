import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('attachment')
export class Attachment extends AppBaseEntity {

    @PrimaryGeneratedColumn()
    IdRow: string;

    @Column({ type: 'text' })
    Id: string;

    @Column({ type: 'bigint' })
    NoteId: number;

    @Column({ type: 'text' })
    FileName: string;

    @Column({ default: false, type: 'boolean' })
    IsDownloaded: boolean;

    @Column({ default: false, type: 'boolean' })
    IsUploaded: boolean;

    @Column({ type: 'bigint' })
    TimeStamp: number;

    @Column({ type: 'text' })
    Format: string;
    @Column({ nullable: true, type: 'text' })
    FileURI: string;

    FormatedFile: any;
    BlobFile: any;
    FileArray: any;
}
