import { Injectable } from '@angular/core';
import { Hierarchy } from '../EntityIndex';
import { HierarchyEntityManagerService } from '../entityManager/hierarchy-entity-manager.service';
import { from, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HierarchyHelperService {
  // hierarchy raw data
  unflattenedHierarchyArray: Hierarchy[];

  // full flattened hierarchy array which includes displayed or hidden hierarchy
  fullFlattenedHierarchyArray: Hierarchy[];

  // displayed flatten hierarchy
  flattenedHierarchyArray: Hierarchy[];

  // selected hierarchy list
  selectedHierarchyPath: Hierarchy[];

  eventId: number;
  provider: string;

  private _scrollPosition = 0;
  public get scrollPosition(): number {
    return this._scrollPosition;
  }
  public set scrollPosition(value: number) {
    this._scrollPosition = value;
  }
  constructor(private hierarchyEntityManager: HierarchyEntityManagerService) {}

  reset(provider: string, eventId: number, force?: boolean) {
    if (this.eventId !== undefined && this.eventId !== eventId) {
      force = true;
    }
    this.scrollPosition = 0;
    this.eventId = eventId;
    this.provider = provider;
    this.unflattenedHierarchyArray = undefined;
    this.flattenedHierarchyArray = undefined;

    if (force) {
      this.fullFlattenedHierarchyArray = undefined;
    } else {
      if (this.fullFlattenedHierarchyArray) {
        this.fullFlattenedHierarchyArray.forEach((element) => {
          element.isMatched = undefined;
          element.isExpanded = false;
        });
        // the first element in hierarchy list is always project level hierarchy and it should be expanded at the begining
        if (this.fullFlattenedHierarchyArray[0]) {
          this.fullFlattenedHierarchyArray[0].isExpanded = true;
        }
      }
    }
  }

  getHierarchyForEnvent(
    provider: string,
    eventId: number
  ): Promise<Hierarchy[]> {
    if (this.provider !== provider || this.eventId !== eventId) {
      this.reset(provider, eventId, true);
    }
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      try {
        if (!this.unflattenedHierarchyArray) {
          this.unflattenedHierarchyArray =
            await this.hierarchyEntityManager.getHierarchyForEnvent(
              eventId,
              provider
            );
        }

        resolve(this.unflattenedHierarchyArray);
      } catch (error) {
        reject(error);
      }
    });
  }

  lazyLoadingFlattenHierarchy(
    provider: string,
    eventId: number,
    onlyActiveWBSs: boolean = false,
    parentId?: number
  ): Promise<Hierarchy[]> {
    let currentHierarchy: Hierarchy = null;
    let currentHierarchyIndex = 0;
    if (this.provider !== provider || this.eventId !== eventId) {
      this.reset(provider, eventId, true);
    }
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      if (!this.unflattenedHierarchyArray) {
        this.flattenedHierarchyArray = [];
        this.unflattenedHierarchyArray = await this.getHierarchyForEnvent(
          provider,
          eventId
        );
      }
      let hierarchyArrayToUse = this.fullFlattenedHierarchyArray
        ? this.fullFlattenedHierarchyArray
        : this.unflattenedHierarchyArray;

      if (onlyActiveWBSs) {
        hierarchyArrayToUse.filter(t => t.IsDeleted).forEach(t => {
          if (t.children) {
            t.children.forEach(c => c.IsDeleted = true);
          }
        });
        hierarchyArrayToUse = hierarchyArrayToUse.filter((t) => !t.IsDeleted);
      }

      if (
        this.flattenedHierarchyArray === undefined ||
        this.flattenedHierarchyArray.length < 1
      ) {
        const ret = hierarchyArrayToUse.filter(
          ele => ele.ForeignId === eventId
        );

        let root: Hierarchy;

        if (ret && ret.length > 0) {
          root = ret[0];
        } else {
          reject('no WBS found.');
        }

        this.flattenedHierarchyArray = [];
        this.flattenedHierarchyArray.push(root);
        currentHierarchy = root;
        currentHierarchyIndex = 0;

        root.children = hierarchyArrayToUse.filter(
          ele => ele.ForeignParentId === eventId
        );
        root.children.forEach((element: Hierarchy) => {
          element.parent = root;
        });
        this.flattenedHierarchyArray.splice(1, 0, ...root.children);
      } else {
        for (
          let index = 0;
          index < this.flattenedHierarchyArray.length;
          index++
        ) {
          const element = this.flattenedHierarchyArray[index];
          if (element.ForeignId === parentId) {
            // find the hierarchy note and expand it
            currentHierarchy = element;
            currentHierarchyIndex = index;
            if (!element.children) {
              const filterRet1 = hierarchyArrayToUse.filter(ele => {
                if (ele.ForeignParentId === parentId) {
                  ele.parent = element;
                  return true;
                } else {
                  return false;
                }
              });
              if (filterRet1 && filterRet1.length > 0) {
                element.children = filterRet1;
                currentHierarchyIndex += filterRet1.length;
                this.flattenedHierarchyArray.splice(
                  index + 1,
                  0,
                  ...filterRet1
                );
              }
            } else {
              // check if children have already added to list
              const filterRet2 = this.flattenedHierarchyArray.filter(ele2 => {
                if (element.children && element.children.length > 0) {
                  return ele2.ForeignId === element.children[0].ForeignId;
                } else {
                  return false;
                }
              });
              if (!filterRet2 || filterRet2.length < 1) {
                this.flattenedHierarchyArray.splice(
                  index + 1,
                  0,
                  ...element.children
                );
              }
              break;
            }

            break;
          } else {
            continue;
          }
        }
      }

      // load activities for hierarchy
      if (currentHierarchy) {
        if (
          !currentHierarchy.activityChecked &&
          currentHierarchy.ForeignEntity !== 'Activity'
        ) {
          const activities = await this.hierarchyEntityManager.getActivitiesForHierarchy(
            currentHierarchy
          );

          if (activities && activities.length > 0) {
            activities.forEach(activity => {
              activity.children = [];
              activity.parent = currentHierarchy;
            });
            currentHierarchy.children = currentHierarchy.children
              ? currentHierarchy.children.concat(activities)
              : activities;
            this.flattenedHierarchyArray.splice(
              currentHierarchyIndex + 1,
              0,
              ...activities
            );
          }

          if (!currentHierarchy.children) {
            currentHierarchy.children = [];
          }
          currentHierarchy.activityChecked = true;
        }
      }
      resolve(this.flattenedHierarchyArray);
    });
  }

  getFlattenHierarchyArrayWithParent(
    eventId: number,
    provider: string
  ): Promise<Hierarchy[]> {
    if (this.provider !== provider || this.eventId !== eventId) {
      this.reset(provider, eventId, true);
    }
    return new Promise<Hierarchy[]>((resolve, reject) => {
      this.getHierarchyForEnvent(provider, eventId).then((rets) => {
        this.unflattenedHierarchyArray = rets;
        this.fullFlattenedHierarchyArray = [];
        this.selectedHierarchyPath = [];
        const hierarchy = this.buildHierarchy(this.unflattenedHierarchyArray);
        this.flattenHierarchyHierarchy(hierarchy);
        resolve(this.fullFlattenedHierarchyArray);
      });
    });
  }

  buildHierarchy(arr: Hierarchy[]) {
    // get the root level - project
    let projectRoot;
    for (const hirEle of arr) {
      if (hirEle.ForeignEntity === 'Project') {
        projectRoot = hirEle;
      }
      hirEle.children = [];
    }
    if (projectRoot) {
      projectRoot.children = this.getNestedChildren(arr, projectRoot.ForeignId);
    }

    return projectRoot;
  }

  getNestedChildren(arr: Hierarchy[], parent: number) {
    const out = [];
    for (const i in arr) {
      if (arr[i].ForeignParentId === parent) {
        const children = this.getNestedChildren(arr, arr[i].ForeignId);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }

  flattenHierarchyHierarchy(unflattenedHierarchy: Hierarchy) {
    if (unflattenedHierarchy) {
      const wbs = unflattenedHierarchy;
      wbs.isExpanded =
        this.fullFlattenedHierarchyArray.length === 0 ? true : false;
      if (wbs.isExpanded) {
        this.selectedHierarchyPath.push(wbs);
      }
      this.fullFlattenedHierarchyArray.push(wbs);
      const unflattenedChildHierarchyhierarchy = unflattenedHierarchy.children;
      if (unflattenedChildHierarchyhierarchy) {
        unflattenedChildHierarchyhierarchy.forEach((childHierarchy) => {
          childHierarchy.parent = wbs;
          this.flattenHierarchyHierarchy(childHierarchy);
        });
      }
    }
  }

  getHierarchyById(
    eventId: number,
    hierarchyId: number,
    provider: string
  ): Promise<Hierarchy> {
    return new Promise<Hierarchy>(async (resolve, reject) => {
      if (this.fullFlattenedHierarchyArray === undefined) {
        await this.getFlattenHierarchyArrayWithParent(eventId, provider);
      }
      let ret: Hierarchy = null;
      for (const f of this.fullFlattenedHierarchyArray) {
        if (f.ForeignId === hierarchyId) {
          ret = f;
          break;
        }
      }

      resolve(ret);
    });
  }

  getHierarchyPathForHierarchy(
    hierarchy: Hierarchy,
    hirerarchyPath: Hierarchy[]
  ) {
    hirerarchyPath.push(hierarchy);
    if (hierarchy.parent) {
      return this.getHierarchyPathForHierarchy(
        hierarchy.parent,
        hirerarchyPath
      );
    } else {
      hirerarchyPath = hirerarchyPath.reverse();
      return hirerarchyPath;
    }
  }

  getHierarchyPathStringForHierarchy(hierarchy: Hierarchy): string {
    let str = '';
    if (hierarchy) {
      const hierarchyPath: Hierarchy[] = [];
      this.getHierarchyPathForHierarchy(hierarchy, hierarchyPath);
      str = hierarchyPath.map((hir) => hir.Name).join(' / ');
    }

    return str;
  }

  filter(query: string, activesOnly: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let fullFlattenedHierarchyArrayObservable = of(
        this.fullFlattenedHierarchyArray
      );

      if (!this.fullFlattenedHierarchyArray) {
        fullFlattenedHierarchyArrayObservable = from(
          this.getFlattenHierarchyArrayWithParent(this.eventId, this.provider)
        );
      }

      fullFlattenedHierarchyArrayObservable.subscribe((hList) => {
        if (hList) {
          this.fullFlattenedHierarchyArray = hList;
        }
        this._onFullFlattenedHierarchyArrayResponse(hList, activesOnly, query);
        resolve(); // since no method is called inside it
      });
    });
  }

  private _onFullFlattenedHierarchyArrayResponse(
    hList: Hierarchy[],
    activesOnly: boolean,
    query: string
  ) {
    this.fullFlattenedHierarchyArray.forEach((hierarchy) => {
      const isMatched = this.isMatch(hierarchy, query);
      hierarchy.isMatched = false;
      if (isMatched) {
        hierarchy.isMatched = true;
        if (!activesOnly) {
          hierarchy = this._getChildIsMatched(hierarchy, query, false);
          let hir = hierarchy;
          while (hir.parent && !hir.parent.isMatched) {
            hir.parent.isMatched = true;
            hir = hir.parent;
          }
        } else if (!hierarchy.IsDeleted) {
          hierarchy = this._getChildIsMatched(hierarchy, query, true);
          let hir = hierarchy;
          while (!hir?.parent?.isMatched && !hir?.parent?.IsDeleted) {
            hir.parent.isMatched = true;
            hir = hir.parent;
          }
        }
      }
    });
  }

  private _getChildIsMatched(
    hierarchy: Hierarchy,
    query: string,
    IsDeleted: boolean
  ): Hierarchy {
    hierarchy.children.forEach((child) => {
      if (IsDeleted)
        child.isMatched = this.isMatch(child, query) && !child.IsDeleted;
      else child.isMatched = this.isMatch(child, query);
    });
    return hierarchy;
  }

  isMatch(item: Hierarchy, toFind: string): boolean {
    if (
      (item.Name === null || typeof item.Name === 'undefined'
        ? ''
        : item.Name.toLowerCase()
      ).includes(toFind.toLowerCase()) ||
      (item.Category === null || typeof item.Category === 'undefined'
        ? ''
        : item.Category.toLowerCase()
      ).includes(toFind.toLowerCase())
    ) {
      return true;
    }

    return false;
  }
}
