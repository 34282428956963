import { Component, OnInit, Input } from '@angular/core';
import { Notes, User } from 'src/data/EntityIndex';
import { UserHelperService } from 'src/data/helper/user-helper.service';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-note-list-cell',
  templateUrl: './note-list-cell.component.html',
  styleUrls: ['./note-list-cell.component.scss']
})
export class NoteListCellComponent implements OnInit {
  @Input() note: Notes;
  user$: Observable<User>;
  constructor(private userHelper: UserHelperService) {}

  ngOnInit() {
    this.user$ = from(this.userHelper.getUserInfo(this.note.CreatedByUPN));
  }
}
