import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { LoadingService } from 'src/services/loading.service';
import { Platform } from '@ionic/angular';
import { DbService } from 'src/services/db.service';

@Injectable({
  providedIn: 'root'
})
export class DBGuard implements CanActivate {
  constructor(
    private loadingService: LoadingService,
    private platform: Platform,
    private dbService: DbService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return from(this.initDB());
  }

  initDB(): Promise<boolean> {
    this.loadingService.present();
    return new Promise<boolean>((resolve, reject) => {
      this.platform.ready().then(() => {
        this.dbService.ready().then(() => {
          this.loadingService.dismiss();
          resolve(true);
        });
      }).catch((e)=> resolve(e));
    });
  }
}
