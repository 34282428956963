import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class SharedPlatformProvider {
  constructor(public platform: Platform) {}

  // tslint:disable-next-line:variable-name
  public _isMobile: boolean = undefined;
  public _isWindows: boolean = undefined;
 
  checkIsMobile() {
    let isMobiledevice = false;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      isMobiledevice = true;
     }
    return isMobiledevice;
  };
   
  public isMobile(): boolean {
    if (this._isMobile === undefined) {
         
       this._isMobile = this.checkIsMobile(); 
      console.log('this._isMobile',this._isMobile); 
      console.log('platform ipad',this.platform.is('ipad'));
      console.log('platform iphone',this.platform.is('iphone'));
      console.log('platform tablet',this.platform.is('tablet'));
      console.log('platform mobile',this.platform.is('mobile'));
    }
   
    return this._isMobile;
  }

 
  
}
