import { Injectable } from '@angular/core';
import { getRepository } from 'typeorm/browser';
import { NoteTag } from '../entity/NoteTag';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { Notes } from 'src/data/entity/Notes';

@Injectable({
  providedIn: 'root'
})
export class NoteTagEntityManagerService extends AppBaseEntityManager {
  protected _entityType = NoteTag;
  constructor() {
    super();
  }

  getTagsForNote(noteId: string): Promise<string[]> {
    return new Promise<string[]>(async (resolve, reject) => {
      const noteRepository = getRepository('noteTag');
      const noteComments = await noteRepository
        .createQueryBuilder('noteTag')
        .where('noteTag.NoteId = :noteId', { noteId })
        .getMany();

      const ret = noteComments.map((comment: NoteTag) => comment.Tag);
      resolve(ret);
    });
  }

  getTagsForNotes(notes: Notes[]): Promise<NoteTag[]> {
    return new Promise<NoteTag[]>(async (resolve, reject) => {
      const noteRepository = getRepository('noteTag');
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const noteTags = await noteRepository
        .createQueryBuilder('noteTag')
        .where(`noteTag.NoteId IN ('${notesIds}')`)
        .getMany();
      resolve(noteTags as NoteTag[]);
    });
  }

  saveTags(tags: NoteTag[]): Promise<void> {
    return this.bulkInsert2(tags);
  }

  deleteTagsForNote(noteId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('noteTag');
        await noteRepository
          .createQueryBuilder()
          .delete()
          .from(NoteTag)
          .where('NoteId = :noteId', { noteId })
          .execute();
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  deleteTagsForNotes(noteIds: string[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('noteTag');
        await noteRepository
          .createQueryBuilder()
          .delete()
          .from(NoteTag)
          .where('noteTag.NoteId in (:noteIds)', {
            noteIds: noteIds.map(noteId => `'${noteId}'`).join(',')
          })
          .execute();
        resolve();
      } catch (error) {
        reject();
      }
    });
  }
}
