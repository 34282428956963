import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { getRepository } from 'typeorm/browser';
import { OBS } from '../EntityIndex';

@Injectable({
  providedIn: 'root'
})
export class ObsEntityManagerService extends AppBaseEntityManager {
  protected _entityType = OBS;

  constructor(
  ) {
    super();
  }

  getSites(): Promise<OBS[]> {
    return new Promise<OBS[]>(async (resolve, reject) => {
      const obsRepository = getRepository(OBS);
      const sites = await obsRepository
        .createQueryBuilder('obs')
        // tslint:disable-next-line:quotemark
        .getMany();
      resolve(sites);
    });
  }

  saveObs(obs: OBS[]): Promise<void> {
    return this.bulkInsert2(obs);
  }
}
