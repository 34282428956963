import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HierarchySelectionMode } from 'src/data/InternalTypes';
import { Observable, from } from 'rxjs';
import { Hierarchy } from 'src/data/EntityIndex';
import { HierarchyHelperService } from 'src/data/helper/hierarchy-helper.service';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProfileHelperService } from 'src/data/helper/profile-helper.service';

@Component({
  selector: 'app-hierarchy-component',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {
  @Input() eventId: number;
  @Input() provider: string;
  @Input() onlyActiveHierarchies: boolean;
  @Input() isFromSavedFilter: boolean;

  // hierarchy view mode, single selection or multi-selection
  @Input() mode: HierarchySelectionMode = HierarchySelectionMode.single;

  // multi-selection mode used fields
  @Input() selectedHierarchies: Hierarchy[]; // all selected hierarchies

  // multi-selection mode used fields
  @Input() selectedCategories: string[]; // all selected categories

  @Input() hideDeleted: boolean;

  @Output() changedHierarchies: EventEmitter<Hierarchy[]> = new EventEmitter();

  // Hierarchy view used fields
  hierarchyObservable: Observable<Hierarchy[]>;
  flattenedHierarchyArray: Hierarchy[];
  treeItems = true;
  filterHierarchyList: Hierarchy[];

  initialHierarchyList: Hierarchy[];
  searchQuery: string;

  // single-selection mode used fields
  selectedHierarchyId: string; // selected hierarchy id for the single-selection mode
  selectedHierarchy: Hierarchy; // selected hierarchy object for the single-selection mode
  selectedHierarchyPath: Hierarchy[]; // hierarchy path for selected hierarchy in the single-selection mode

  constructor(
    private hierarchyHelper: HierarchyHelperService,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private profileService: ProfileHelperService
  ) { }

  ngOnInit() {
    this.selectedHierarchies = this.selectedHierarchies
      ? this.selectedHierarchies
      : [];
    this.flattenedHierarchyArray = [];
    this.selectedHierarchyPath = [];
    this.hierarchyObservable = from(
      this.hierarchyHelper.lazyLoadingFlattenHierarchy(
        this.provider,
        this.eventId,
        this.onlyActiveHierarchies,
        this.eventId
      )
    );

    this.hierarchyObservable.subscribe(hList => {
      // Needs more work but basic construct is present
      // if (this.selectedCategories && this.selectedCategories.length > 0)
      //   hList = hList.filter(h => { return (this.selectedCategories.includes(h.Category)); });
      this.flattenedHierarchyArray = hList;
      if (this.isFromSavedFilter) {
        let storedHierarchyArray = JSON.parse(localStorage.getItem('hierarchyArray'));
        storedHierarchyArray = storedHierarchyArray === null ? [] : storedHierarchyArray;
        storedHierarchyArray = storedHierarchyArray.find(x => x.projectId === this.profileService.profile.preferredEventId);
        if (storedHierarchyArray) {
          this.flattenedHierarchyArray = storedHierarchyArray.hierarchy;
          this.hierarchyHelper.flattenedHierarchyArray = storedHierarchyArray.hierarchy;
        }
      }
      from(
        this.hierarchyHelper.getHierarchyForEnvent(this.provider, this.eventId)
      ).subscribe(hList2 => {
        this.initialHierarchyList = hList2;
      });
    });
  }

  async searchWbs() {
    if (this.searchQuery === '') {
      this.filterHierarchyList = [];
      this.treeItems = true;
    } else {
      this.filterHierarchyList = [];
      this.treeItems = false;
      this.initialHierarchyList.forEach(element => {
        if (this.isMatch(element, this.searchQuery)) {
          this.filterHierarchyList.push(element);
        }
      });
    }
  }

  isMatch(item: Hierarchy, toFind: string): boolean {
    if (
      (item.Name === null || typeof item.Name === 'undefined'
        ? ''
        : item.Name.toLowerCase()
      ).includes(toFind.toLowerCase()) ||
      (item.Category === null || typeof item.Category === 'undefined'
        ? ''
        : item.Category.toLowerCase()
      ).includes(toFind.toLowerCase())
    ) {
      return true;
    }

    return false;
  }

  async selectWbs(hierarchy?: Hierarchy) {
    if (this.selectedHierarchyId) {
      this.filterHierarchyList.forEach(element => {
        if (
          this.selectedHierarchyId !== undefined &&
          element.ForeignId === parseInt(this.selectedHierarchyId, 10)
        ) {
          this.selectedHierarchy = element;
        }
      });

      this.selectedHierarchies = [this.selectedHierarchy];
      this.changedHierarchies.emit(this.selectedHierarchies);
    }
  }

  async searchItemList() {
    // Note: Loading service does not work in model controller
    const loading = await this.loadingController.create({
      message: this.translate.instant('SHARED.Searching')
    });
    loading.present();
    this.hierarchyHelper.filter(this.searchQuery, this.onlyActiveHierarchies).then(() => {
      loading.dismiss();
    });
  }

  async selectHierarchy(hierarchy?: Hierarchy) {
    switch (this.mode) {
      case HierarchySelectionMode.single:
        this._onSingleHierarchySelected();
        break;
      case HierarchySelectionMode.multiple:
        this._onMultipleHierarchySelected(hierarchy);
        break;
      default:
        break;
    }
  }

  private _onSingleHierarchySelected() : void {
    if (!this.selectedHierarchyId) {
      this.selectedHierarchyPath = [];
    }
    // get hierarchy from selected hierarchy id
    for (const flattenedHierarchy of this.flattenedHierarchyArray) {
      if (flattenedHierarchy.isExpanded) {
        this.selectedHierarchyPath.push(flattenedHierarchy);
      }
      if (
        this.selectedHierarchyId !== undefined &&
        flattenedHierarchy.ForeignId === parseInt(this.selectedHierarchyId, 10)
      ) {
        this.selectedHierarchy = flattenedHierarchy;
        break;
      }
    }

    if (this.selectedHierarchyId !== undefined) {
      this.selectedHierarchies = [this.selectedHierarchy];
      this.changedHierarchies.emit(this.selectedHierarchies);
    }
  }

  private _onMultipleHierarchySelected(hierarchy?: Hierarchy) : void {
    let foundFlag = false;
    for (let index = 0; index < this.selectedHierarchies.length; index++) {
      const element = this.selectedHierarchies[index];
      if (element.ForeignId === hierarchy.ForeignId) {
        foundFlag = true;
        if (!hierarchy.isChecked) {
          // remove the hierarchy from the array since it is un-checked
          this.selectedHierarchies.splice(index, 1);
          break;
        }
      }
    }
    if (!foundFlag) {
      this.selectedHierarchies.push(hierarchy);
    } else {
      hierarchy.isChecked = false;
    }
    this.changedHierarchies.emit(this.selectedHierarchies);

  }

  expandHierarchy(hierarchy: Hierarchy) {
    switch (this.mode) {
      case HierarchySelectionMode.single:
        // only get the selected hierarchy path for the single mode
        this.selectedHierarchyPath = [];
        this.hierarchyHelper.getHierarchyPathForHierarchy(
          hierarchy,
          this.selectedHierarchyPath
        );
        break;
      case HierarchySelectionMode.multiple:
        break;

      default:
        break;
    }

    // TODO: Loading sign does not working on pop up

    this.hierarchyHelper
      .lazyLoadingFlattenHierarchy(
        this.provider,
        hierarchy.EventId,
        this.onlyActiveHierarchies,
        hierarchy.ForeignId
      )
      .then(ret => {
        if (!hierarchy.isExpanded) {
          this.cllapseChildHierarchyPath(hierarchy);
        } else {
          // collapse all other hierarchy path
          const parentHierarchy = hierarchy.parent;
          if (parentHierarchy && parentHierarchy.children) {
            parentHierarchy.children.forEach(childHierarchy => {
              if (childHierarchy.ForeignId !== hierarchy.ForeignId) {
                // sibling hierarchy
                childHierarchy.isExpanded = false;
                this.cllapseChildHierarchyPath(childHierarchy);
              }
            });
          }
        }

        this.flattenedHierarchyArray = ret;
      });
  }

  cllapseChildHierarchyPath(hierarchy: Hierarchy) {
    if (hierarchy.children) {
      hierarchy.children.forEach(childHierarchy => {
        childHierarchy.isExpanded = false;
        this.cllapseChildHierarchyPath(childHierarchy);
      });
    } else {
      return;
    }
  }
}
