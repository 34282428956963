import { Column, Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('Summary')
export class Summary extends AppBaseEntity {
  @PrimaryColumn({ type: 'text' })
  Id: string;

  @Column({ type: 'text' })
  SummaryComment: string;

  @Column({ type: 'bigint' })
  EventId: number;

  @Column({ nullable: true, type: 'text' })
  ShiftDate: string;

  @Column({ nullable: true, type: 'text' })
   Role: string;

   @Column({ nullable: true, default: true, type: 'boolean' })
   IsUploaded: boolean;
}
