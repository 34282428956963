import { Column, Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('user')
export class User extends AppBaseEntity {
  @PrimaryColumn({ type: 'text' })
  UPN: string;

  @Column({ nullable: true, type: 'text' })
  Email: string;

  @Column({ nullable: true, type: 'text' })
  Cai: string;

  @Column({ nullable: true, type: 'text' })
  FirstName: string;

  @Column({ nullable: true, type: 'text' })
  LastName: string;

}
