import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { Platform } from '@ionic/angular';
import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage/ngx';


@Injectable()
export class AppStorageProvider {
  constructor(private secureStorage: SecureStorage, private platform: Platform) { }

  private getKey(): string {
    return Constants.SecureStorageKey;
  }

  /**
   * Get the value from local storage using the key provided
   * @param key - Get the value for the key stored in storage
   * @param fromSecure - Optional parameter to find key in secure storage or not, default is false
   */
  GetLocal(key: string, fromSecure?: boolean): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (fromSecure && this.platform.is('cordova')) {
        const storage: SecureStorageObject = await this.secureStorage.create(this.getKey());
        if (storage) {
          resolve(await storage.get(key));
        } else {
          reject('Secure Storage failed');
        }
      } else {
        resolve(localStorage.getItem(key));
      }
    });
  }

  /**
   * Set value to local storage using the key provided
   * @param key - Key used to store the value in storage
   * @param value - Value to be stored in the storage
   * @param toSecure - Optional parameter to find store in secure storage or not, default is false
   */
  SetLocal(key: string, value: any, toSecure?: boolean): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (toSecure && this.platform.is('cordova')) {
        const storage: SecureStorageObject = await this.secureStorage.create(this.getKey());
        if (storage) {
          resolve(await storage.set(key, value));
        } else {
          reject('Secure Storage failed');
        }
      } else {
        localStorage.setItem(key, value);
        resolve();  // since no method is called inside it
      }
    });
  }

  /**
   * Clear Local Storage
   * @param clearSecure - Clear Securage storage or not.
   */
  Clear(clearSecure?: boolean): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (clearSecure && this.platform.is('cordova')) {
        const storage: SecureStorageObject = await this.secureStorage.create(this.getKey());
        if (storage) {
          resolve(await storage.clear());
        } else {
          reject('Secure Storage failed');
        }
      } else {
        localStorage.clear();
      }
    });
  }
}
