import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute
} from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { LoadingService } from 'src/services/loading.service';
import { Platform } from '@ionic/angular';
import { SyncService } from 'src/services/sync/SyncService';
import { DBGuard } from './DBGuard';
import { switchMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SyncGuard implements CanActivate {
  constructor(
    private loadingService: LoadingService,
    private platform: Platform,

    private router: Router,
    private route: ActivatedRoute,

    private syncServ: SyncService,

    private dbGuard: DBGuard
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const p = next.params.provider;
    const e = next.params.eventId;
    const c = next.params.isCompleted;
    const currentNavigation = this.router.getCurrentNavigation();

    return this.dbGuard
      .canActivate(next, state)
      .pipe(switchMapTo(this.checkSync(currentNavigation, p, e, c)));
  }

  checkSync(currentNavigation: any, p: string, e: number, c: boolean): Observable<boolean> {
    if (
      currentNavigation &&
      currentNavigation.extras &&
      currentNavigation.extras.state &&
      currentNavigation.extras.state.isSyncEnabled
    ) {
      return from(this.sync(p, e, c));
    } else {
      return of(true);
    }
  }

  sync(provider: string, eventId: number, isCompleted: boolean): Promise<boolean> {
    this.loadingService.present();
    return new Promise<boolean>((resolve, reject) => {
      this.platform.ready().then(() => {
        this.syncServ
          .syncDataForEvent(provider, eventId, isCompleted)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            this.loadingService.dismiss();
            resolve(true);
          })
          .finally(() => {
            this.loadingService.dismiss();
          });
      });
    });
  }
}
