import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { retry, timeout } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  protected baseURL = environment.apiEndpoint;

  constructor(
    private http: HttpClient,
    private network: Network) { }

  async pingServer() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (this.network.type !== 'none') {
          this.http.get(`${this.baseURL}/System/alive`)
            .pipe(retry(10))
            .pipe(timeout(180000))
            .subscribe(
              (result: any) => {
                resolve(true); // TODO - How to filter exception from Azure API's
              },
              async (error: HttpErrorResponse) => {
                resolve(false);
              }
            );
        } else {
          resolve(false);
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
