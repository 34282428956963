import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from './AppBaseService';
import { HttpParams, HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { Code } from '../EntityIndex';
import { AuthService } from 'src/services/auth.service';
import { ADALProvider } from 'src/shared/adal/adal';
import { SynclogEntityManagerService } from '../entityManager/synclog-entity-manager.service';

@Injectable({
  providedIn: 'root',
})
export class CodeService extends AppBaseServiceProvider {
  serviceName = 'Code';
  currentVersion = '1.0';

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public synclogEntityManager: SynclogEntityManagerService,
    public adalService: ADALProvider
  ) {
    super(http, authService, synclogEntityManager, adalService);
  }

  getCodes(): Promise<Code[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<any[]>(async (resolve, reject) => {
      this.http
        .get<Code[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(30000))
        .subscribe(
          (val) => {
            resolve(val);
          },
          (error) => {
            reject(error);
          },
          () => {
            console.log('HTTP request completed.');
          }
        );
    });
  }

  getItems(eventId: number, dateFrom: string, dateTo: string): Promise<any[]> {
    let httpParams = new HttpParams();

    if (dateFrom) httpParams = httpParams.append('DateFrom', dateFrom);
    if (dateTo) httpParams = httpParams.append('DateTo', dateTo);
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<any[]>(async (resolve, reject) => {
      this.http
        .get<any[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(300000))
        .subscribe(
          (vals) => {
            const helperValues = this.getNewVals(vals);
            if (vals.length > 0) {
              this.syncLogerEntityManager
                .createUploadSyncLogForEvent(
                  helperValues.provider,
                  eventId,
                  this.serviceName,
                  helperValues.largestValue
                )
                .then(() => {
                  resolve(helperValues.newVals);
                });
            } else {
              resolve(helperValues.newVals);
            }
          },
          (error) => {
            reject(error);
          },
          () => {
            console.log('HTTP request completed.');
          }
        );
    });
  }

  getRoles(): Promise<Code[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('api-version', this.currentVersion)
      .append('category', 'role');

    return new Promise<any[]>(async (resolve, reject) => {
      this.http
        .get<Code[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams,
        })
        .pipe(timeout(30000))
        .subscribe(
          (val) => {
            resolve(val);
          },
          (error) => {
            reject(error);
          },
          () => {
            console.log('HTTP request completed.');
          }
        );
    });
  }
}
