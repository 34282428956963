import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from './AppBaseService';
import { HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { AppBaseEntity } from '../EntityIndex';

@Injectable({
  providedIn: 'root',
})
export class OBSService extends AppBaseServiceProvider {
  serviceName = 'Hierarchy';
  downloadOperationName = 'obs';
  currentVersion = '1.0';

  getItems(eventId: number, dateFrom: string, dateTo: string): Promise<any[]> {
    let httpParams = new HttpParams();

    if (dateFrom) httpParams = httpParams.append('DateFrom', dateFrom);
    if (dateTo) httpParams = httpParams.append('DateTo', dateTo);
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<any[]>(async (resolve, reject) => {
      this.http
        .get<any[]>(
          `${this.baseURL}/${this.serviceName}/${this.downloadOperationName}`,
          {
            params: httpParams,
          }
        )
        .pipe(timeout(300000))
        .subscribe(
          (vals) => {
            const helperValues = this.getNewVals(vals);
            if (vals.length > 0) {
              this.syncLogerEntityManager
                .createUploadSyncLogForEvent(
                  helperValues?.provider,
                  -1,
                  this.downloadOperationName,
                  helperValues?.largestValue
                )
                .catch(err => {
                  reject(err);
                })
                .then(() => {
                  resolve(helperValues?.newVals);
                });
            } else {
              resolve(helperValues?.newVals);
            }
          },
          (error) => {
            reject(error);
          },
          () => { }
        );
    });
  }

  getIncrementalItems(provider: string): Promise<any[]> {
    return new Promise<AppBaseEntity[]>((resolve, reject) => {
      this.syncLogerEntityManager
        .getLastSyncLogsForEntitiesByEnent(
          provider,
          NaN,
          this.downloadOperationName
        )
        .then((log) => {
          const dateFrom = log ? log.LastDownloadTime : null;
          this.getItems(-1, dateFrom, null)
            .then((rets) => {
              resolve(rets);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
