import { Injectable } from '@angular/core';
import { UserEntityManagerService } from '../EntityManagerIndex';
import { User } from '../entity/User';
import { ADALProvider } from 'src/shared/adal/adal';

@Injectable({
  providedIn: 'root',
})
export class UserHelperService {
  constructor(
    private userEntityManager: UserEntityManagerService,
    private adalService: ADALProvider
  ) {}
  private usersMap: any = {};
  private users: User[];
  private isInited = false;

  buildUsers(force?: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.isInited && !force) {
        resolve();
      } else {
        this.userEntityManager
          .getAll()
          .then((rets) => {
            this.users = rets as User[];
            this.users.forEach((user) => {
              this.usersMap[user.UPN] = user;
            });
            this.isInited = true;
            resolve();
          })
          .catch((e) => reject(e));
      }
    });
  }
  getMyInfo(): User {
    return new User({ UPN: this.adalService.getUPN() });
  }

  getUserInfo(upn: string): Promise<User> {
    return new Promise<User>(async (resolve, reject) => {
      try {
        const userInfo = this.usersMap ? this.usersMap[upn] : null;
        if (userInfo) {
          // get from memory
          resolve(userInfo);
        } else {
          // get from db
          this.userEntityManager.getUser(upn).then((user) => {
            this.usersMap[upn] = user;
            resolve(user);
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  searchUser(query: string): Promise<User[]> {
    const queryInUpperCase = query.toUpperCase();
    return new Promise<User[]>((resolve, reject) => {
      this.buildUsers()
        .then(() => {
          const filtedUsers = this.users.filter(
            (user) =>
              (user.FirstName &&
                user.FirstName.toUpperCase().indexOf(queryInUpperCase) !==
                  -1) ||
              (user.LastName &&
                user.LastName.toUpperCase().indexOf(queryInUpperCase) !== -1) ||
              (user.Cai &&
                user.Cai.toUpperCase().indexOf(queryInUpperCase) !== -1)
          );
          resolve(filtedUsers);
        })
        .catch((e) => reject(e));
    });
  }

  getUsersWithNotes(eventId: number): Promise<string[]> {
    return new Promise<string[]>(async (resolve, reject) => {
      this.userEntityManager
        .getUserUpnsWithNotes(eventId)
        .then((rets) => {
          resolve(rets);
        })
        .catch((e) => reject(e));
    });
  }
}
