import { Injectable } from '@angular/core';
import { Hierarchy, Notes } from '../EntityIndex';
import { getRepository, Brackets } from 'typeorm/browser';
import { Filter } from '../InternalTypes';
import { LoadingService } from 'src/services/loading.service';
import { NoteEntityManagerService } from './note-entity-manager.service';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { Constants } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class HierarchyEntityManagerService extends AppBaseEntityManager {
  protected _entityType = Hierarchy;

  constructor(
    private loading: LoadingService,
    private noteManager: NoteEntityManagerService
  ) {
    super();
  }

  getHierarchyForEnvent(
    eventId: number,
    provider: string
  ): Promise<Hierarchy[]> {
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      const hirerarchyRepository = getRepository(Hierarchy);
      const hierarchies = await hirerarchyRepository
        .createQueryBuilder('hierarchy')
        .where(
          `hierarchy.EventId = :eventId and Provider = :provider and ForeignEntity != 'Activity'`,
          {
            eventId,
            provider,
          }
        )
        .getMany();

      resolve(hierarchies);
    });
  }

  getEnvents(): Promise<Hierarchy[]> {
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      const hirerarchyRepository = getRepository(Hierarchy);
      const hierarchies = await hirerarchyRepository
        .createQueryBuilder('hierarchy')
        // tslint:disable-next-line:quotemark
        .where("hierarchy.ForeignParentId = ''")
        .orWhere('hierarchy.ForeignParentId isnull')
        .getMany();

      resolve(hierarchies);
    });
  }

  saveHierarchies(hierarchies: Hierarchy[]): Promise<void> {
    return this.bulkInsert2(hierarchies);
  }

  getChildrenForHierarchy(hierarchy: Hierarchy): Promise<Hierarchy[]> {
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      const hirerarchyRepository = getRepository(Hierarchy);
      const hierarchies = await hirerarchyRepository
        .createQueryBuilder('hierarchy')
        .where(
          'hierarchy.ForeignParentId = :parentId and Provider = :provider',
          {
            parentId: hierarchy.ForeignId,
            provider: hierarchy.Provider,
          }
        )
        .orderBy('hierarchy.ForeignId', 'ASC')
        .getMany();
      resolve(hierarchies);
    });
  }

  getActivitiesForHierarchy(hierarchy: Hierarchy): Promise<Hierarchy[]> {
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      const hirerarchyRepository = getRepository(Hierarchy);
      const hierarchies = await hirerarchyRepository
        .createQueryBuilder('hierarchy')
        .where(
          `hierarchy.ForeignParentId = :parentId and Provider = :provider and ForeignEntity = 'Activity'`,
          {
            parentId: hierarchy.ForeignId,
            provider: hierarchy.Provider,
          }
        )
        .orderBy('hierarchy.DisplayId', 'ASC')
        .getMany();
      resolve(hierarchies);
    });
  }

  getChildrenForHierarchyWithSelf(hierarchy: Hierarchy): Promise<Hierarchy[]> {
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      if (hierarchy && !isNaN(hierarchy.ForeignId)) {
        const hirerarchyRepository = getRepository(Hierarchy);
        const hierarchies = await hirerarchyRepository
          .createQueryBuilder('hierarchy')
          .where(
            'hierarchy.ForeignParentId = :parentId and Provider = :provider',
            {
              parentId: hierarchy.ForeignId,
              provider: hierarchy.Provider,
            }
          )
          .orderBy('hierarchy.ForeignId', 'ASC')
          .getMany();

        // add hierarchy itself to the list
        hierarchies.unshift(hierarchy);

        resolve(hierarchies);
      } else {
        resolve(null);
      }
    });
  }

  getProjectEventByEventId(eventId: number): Promise<Hierarchy> {
    return new Promise<Hierarchy>(async (resolve, reject) => {
      const eventRepository = getRepository(Hierarchy);
      const event = await eventRepository
        .createQueryBuilder('hierarchy')
        // tslint:disable-next-line:quotemark
        .where(`EventId='${eventId}' and ForeignEntity = 'Project'`)
        .getOne();
      resolve(event);
    });
  }

  getHierarchyCategories(eventId: number, provider: string): Promise<string[]> {
    return new Promise<string[]>(async (resolve, reject) => {
      const hirerarchyRepository = getRepository(Hierarchy);
      const categories: string[] = [];
      await hirerarchyRepository
        .createQueryBuilder('hierarchy')
        .select('DISTINCT hierarchy.Category')
        .where('hierarchy.EventId = :eventId and Provider = :provider', {
          eventId,
          provider,
        })
        .orderBy('hierarchy.Category', 'ASC')
        .getRawMany()
        .then((res) => {
          res.forEach((element) => {
            if (element.Category) {
              categories.push(element.Category);
            }
          });
        });
      resolve(categories);
    });
  }

  getFilteredHierarchybyNotes(
    filter: Filter,
    eventId?: number,
    offset?: number,
    filteredTags?: Array<string>
  ): Promise<Hierarchy[]> {
    return new Promise<Hierarchy[]>(async (resolve, reject) => {
      try {
        if (filteredTags?.length > 0) {
          filter.tags = filteredTags;
        }
        const notes = await this.noteManager.getFilteredNotes(
          filter,
          eventId
        );
        let hierarchies: Hierarchy[] = null;
        const noteParentIds = notes?.map((element) => element.ParentId);
        const uniqueHIds = [...new Set(noteParentIds)];
        const hirerarchyRepository = getRepository(Hierarchy);
        const hierarchyQuery =
          hirerarchyRepository.createQueryBuilder('hierarchy');
        if (uniqueHIds?.length > 0) {
          hierarchyQuery.whereInIds(uniqueHIds);
        }
        if (
          filter?.hierarchyCategory?.length > 0
        ) {
          // tslint:disable-next-line:quotemark
          const catStatement: string = filter.hierarchyCategory.join("','");
          hierarchyQuery.andWhere(
            `hierarchy.Category IN ('${catStatement}')`
          );
        }
        if (
          filter.search !== '' &&
          filter.search !== null &&
          filter.search !== undefined
        ) {
          hierarchyQuery.andWhere(
            new Brackets((searchConditon) => {
              searchConditon
                .where('lower(hierarchy.Name) like :search', {
                  search: '%' + filter.search.toLowerCase() + '%',
                })
                .orWhere('lower(hierarchy.Category) like :search', {
                  search: '%' + filter.search.toLowerCase() + '%',
                });
            })
          );
        }
        hierarchyQuery.orderBy('hierarchy.UpdatedDate', 'DESC');
        hierarchies = await hierarchyQuery.getMany().finally();
        if (hierarchies) {
          hierarchies = this._evalHierarchies(hierarchies, notes, offset);
        }
        resolve(hierarchies);
      } catch (error) {
        reject(error);
      }
    });
  }

  private _evalHierarchies (h: Hierarchy[], notes: Notes[], offset: number) {
    let hierarchies : Hierarchy[] = h;
    for (const hierarchy of hierarchies) {
      for (const note of notes) {
        if (hierarchy.ForeignId === note.ParentId) {
          if (!hierarchy.notes) {
            hierarchy.notes = [];
          }
          hierarchy.notes.push(note);
        }
      }
    }
    if (hierarchies) {
      hierarchies.sort((a, b) =>
        Date.parse(a.notes[0].UpdatedDate) <
        Date.parse(b.notes[0].UpdatedDate)
          ? 1
          : -1
      );
      hierarchies = hierarchies.slice(
        offset,
        offset + Constants.pagingSize
      );
    }
    return hierarchies;
  }

  getNotesCountForHierarchy(
    hierarchy: Hierarchy,
    user?: string
  ): Promise<number> {
    return this.noteManager.getNotesCountForHierarchy(hierarchy, user);
  }

  getHierarchyById(
    id: number,
    eventId: number,
    provider: string
  ): Promise<Hierarchy> {
    const hirerarchyRepository = getRepository(Hierarchy);
    return hirerarchyRepository
      .createQueryBuilder('hierarchy')
      .where(
        'hierarchy.ForeignId = :id and hierarchy.EventId = :eventId and hierarchy.Provider = :provider',
        {
          id,
          eventId,
          provider,
        }
      )
      .getOne();
  }
}
