import { Column, Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('noteComment')
export class NoteComment extends AppBaseEntity {
  @PrimaryColumn({ type: 'text' })
  Id: string;

  @Column({ type: 'text' })
  NoteId: string;

  @Column({ type: 'text' })
  Type: string;

  @Column({ nullable: true, type: 'text' })
  Text: string;

  @Column({ nullable: true, type: 'text' })
  KazakText: string;

  @Column({ nullable: true, type: 'text' })
  RussianText: string;
}
