import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notes } from 'src/data/entity/Notes';
import { Hierarchy, User } from 'src/data/EntityIndex';
import { UserHelperService } from 'src/data/helper/user-helper.service';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-note-detail',
  templateUrl: './note-detail.component.html',
  styleUrls: ['./note-detail.component.scss']
})
export class NoteDetailComponent implements OnInit {
  @Input() note: Notes;
  @Input() hierarchy: Hierarchy;
  user$: Observable<User>;

  action:string;
  constructor(private router: Router, private userHelper: UserHelperService) {}

  ngOnInit() {
    this.action = localStorage.getItem('action');
    console.log(this.action);
    this.user$ = from(this.userHelper.getUserInfo(this.note.CreatedByUPN));
  }

  navigateToNoteDetails(note: Notes) {
    this.router.navigate([
      'note-details',
      this.hierarchy.Provider,
      note.EventId,
      note.Id
    ]);
  }

  // getUser(note: Notes): Promise<User> {
  //   return this.userHelper.getUserInfo(note.CreatedByUPN);
  // }

  getUser(note: Notes): Observable<User> {
    return from(this.userHelper.getUserInfo(note.CreatedByUPN));
  }

  getNoteFirstCommentForType(note: Notes, commentType: string): string {
    let commentText = '';
    for (const comment of note.Comments) {
      const language = localStorage.getItem("language");
      if (comment.Type === commentType) {
        commentText = comment.Text;
        if(language === 'kaz'){
          commentText = comment.KazakText;
        }
        if(language === 'ru'){
          commentText = comment.RussianText;
        }
        break;
      }
    }
    return commentText;
  }

}
