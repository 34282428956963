import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from '../entityService/AppBaseService';
import { HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { Profile } from '../model/Profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileServiceProvider extends AppBaseServiceProvider {
  serviceName = 'Profile';
  downloadOperationName = 'current';

  uploadOperationName = 'profile';
  currentVersion = '1.0';

  getProfile(): Promise<Profile> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<Profile>(async (resolve, reject) => {
      this.http
        .get<Profile>(
          `${this.baseURL}/${this.serviceName}/${this.downloadOperationName}`,
          {
            params: httpParams
          }
        )
        .pipe(timeout(30000))
        .subscribe(
          val => {
            resolve(val);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  saveProfile(profile: Profile): Promise<Profile> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);
    return new Promise<Profile>(async (resolve, reject) => {
      this.http
        .post<Profile>(`${this.baseURL}/${this.serviceName}`, profile, {
          params: httpParams
        })
        .pipe(timeout(30000))
        .subscribe(
          vals => {
            resolve(vals);
          },
          error => {
            reject(error);
          },
          () => {}
        );
    });
  }
}
