import { Injectable } from '@angular/core';
import { WindowRef } from '../window-ref';

@Injectable({
    providedIn: 'root',
})
export class ActivationContextProvider {

    constructor(private winRef: WindowRef) {
    }

    /**
     * returns application activation context that was received during launch of app.
     * Cordova overrides onactiviate event of UWP and stores the context data in activationContext property
     */
    getActivationContext(): any {
        try {
            return this.winRef.nativeWindow.cordova.require('cordova/platform').activationContext;
        } catch (e) {
            return e;
        }
    }

    /**
     * returns application activiation kind - like url based activation or user launched app etc.
     */
    getActivationContextKind(): any {
        try {
            return this.winRef.nativeWindow.cordova.require('cordova/platform').activationContext.kind;
        } catch (e) {
            return e;
        }
    }

    resetActivationContext() {
        try {
            this.winRef.nativeWindow.cordova.require('cordova/platform').activationContext = null;
        } catch (e) {
            console.error('cannot reset activation context');
        }
    }

    /**
     * returns the protocol activation type
     */
    get protocolKind() {
        try {
            return this.winRef.nativeWindow.Windows.ApplicationModel.Activation.ActivationKind.protocol;
        } catch (e) {
            return e;
        }
    }

    async launchExternalApp(schemeName, schemeParams) {
        const uri = new this.winRef.nativeWindow.Windows.Foundation.Uri(schemeName + schemeParams);
        await this.winRef.nativeWindow.Windows.System.Launcher.launchUriAsync(uri);
    }

}
