import { Injectable } from '@angular/core';
import { Guid } from 'src/shared/GUID';
import { HttpClient, HttpParams } from '@angular/common/http';
import { File } from '@ionic-native/file/ngx';
import { Attachment } from '../entity/Attachment';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { getRepository } from 'typeorm';
import { NoteAttachmentEntityManagerService } from '../EntityManagerIndex';

@Injectable({
  providedIn: 'root',
})
export class AttachmentHelperService {
  protected baseURL = environment.apiEndpoint;
  constructor(
    private httpClient: HttpClient,
    private file: File,
    private noteAttachmentEntityManager: NoteAttachmentEntityManagerService
  ) {}

  saveAttachments(attachments: Array<Attachment>) {
    return new Promise<void>((resolve, reject) => {
      const calls: Array<any> = this._getCalls(attachments);
      forkJoin(calls).subscribe(async (savedAttachments) => {
        // set returned index and update
        const attachmentRepository = getRepository(Attachment);
        for (const [index, id] of savedAttachments.entries()) {
          if (attachments[index].Id === '') {
            const att = await attachmentRepository.findOne({
              where: { TimeStamp: attachments[index].TimeStamp },
            });
            if (att) {
              att.Id = id.toString();
              att.IsUploaded = true;
              await att.save();
            } else {
              attachments[index].Id = id.toString();
              attachments[index].IsUploaded = true;
              await attachments[index].save();
            }
          }
        }

        const NoteIdArray = attachments.map((attachment) =>
          attachment.NoteId.toString()
        );
        const uniqueNoteId = [...new Set(NoteIdArray)];
        await this.noteAttachmentEntityManager.deleteAttachmentsForNotesNoLongerAvailable(
          uniqueNoteId
        );
        console.log(savedAttachments);
        resolve();
      });
    });
  }

  private _getCalls(attachments: Array<Attachment>): Array<any> {
    const calls: Array<any> = [];
    attachments.forEach((attachment) => {
      if (attachment && attachment.FormatedFile) {
        const formData = new FormData();
        const contentType = this.getContentType(attachment.FormatedFile);
        const DataBlob = this.base64toBlob(
          attachment.FormatedFile,
          contentType
        );
        formData.append('file', DataBlob);
        formData.append('attachment', JSON.stringify(attachment));
        if (attachment.Id === '') {
          calls.push(
            this.httpClient.post(
              `${this.baseURL}/Attachment/saveattachment`,
              formData
            )
          );
        } else {
          calls.push(
            this.httpClient.put(
              `${this.baseURL}/Attachment/updateimages`,
              attachment
            )
          );
        }
      } else if (attachment.Id) {
        calls.push(
          this.httpClient.put(
            `${this.baseURL}/Attachment/updateimages`,
            attachment
          )
        );
      }
    });
    return calls;
  }

  updateAttachments(attachments: Array<Attachment>): Promise<void> {
    return new Promise<void>((resolve, reject) => {});
  }

  deleteAttachments(attachments: Array<Attachment>): Promise<void> {
    return new Promise<void>((resolve, reject) => {});
  }

  downloadAttachments(noteId: Guid): Promise<Array<any>> {
    return new Promise<Array<Attachment>>((resolve, reject) => {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('NoteId', noteId.toString());
      this.httpClient
        .get(`${this.baseURL}/Attachment/downloadimages`, {
          params: httpParams,
        })
        .subscribe((attachments: Array<any>) => {
          resolve(attachments);
        });
    });
  }

  // here is the method is used to convert base64 data to blob data
  public base64toBlob(b64Data, contentType) {
    const imageType = typeof b64Data;
    if (imageType == 'object') {
      b64Data = b64Data.changingThisBreaksApplicationSecurity;
    }
    contentType = contentType || '';
    const sliceSize = 512;
    const data = b64Data.split(',')[1];
    const byteCharacters = atob(data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
    });
    return blob;
  }

  // here is the method is used to get content type of an bas64 data
  getContentType(base64Data: any) {
    const imageType = typeof base64Data;
    if (imageType == 'object') {
      base64Data = base64Data.changingThisBreaksApplicationSecurity;
    }
    const block = base64Data.split(';');
    const contentType = block[0].split(':')[1];
    return contentType;
  }

  // get images from local
  async getImageBlobFile(attachment: Attachment) {
    return new Promise<string>(async (resolve, reject) => {
      const path = attachment.FileURI.substring(
        0,
        attachment.FileURI.lastIndexOf(attachment.FileName)
      );
      const fileName = attachment.FileName;
      this.file.readAsDataURL(path, fileName).then((base64) => {
        resolve(base64);
      });
    });
  }
}
