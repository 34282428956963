import { Downloadable, Uploadable } from '../../interface/SyncInterface';
import {
  BaseEntity,
  getConnection,
  Connection,
  QueryRunner
} from 'typeorm/browser';
import { AppBaseEntity } from '../entity/AppBaseEntity';
import { Constants } from 'src/shared/constants';
import { SyncConfig } from 'src/interface/SyncConfig';

export class AppBaseEntityManager {
  protected static _instance: AppBaseEntityManager;
  protected _entityType = AppBaseEntity;

  static get Instance(): AppBaseEntityManager {
    return this._instance || (this._instance = new this());
  }

  isDownloadable(): this is Downloadable {
    return 'download' in this;
  }

  isUploadable(): this is Uploadable {
    return 'upload' in this;
  }

  description(): string {
    return `${this._entityType.name}`;
  }

  async getAll(): Promise<BaseEntity[]> {
    return new Promise<BaseEntity[]>(async (resolve, reject) => {
      const connection = getConnection();
      const records = await connection
        .getRepository(this._entityType.name)
        .find();
      resolve(records as BaseEntity[]);
    });
  }

  async deleteAll(): Promise<void> {
    const connection = getConnection();
    const queryRunner = connection.createQueryRunner();
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!connection.isConnected) {
          await queryRunner.connect();
        }
        await queryRunner.startTransaction();
        connection
          .createQueryBuilder(queryRunner)
          .delete()
          .from(this._entityType)
          .execute();
        resolve();
      } catch (e) {
        if (queryRunner.isTransactionActive) {
          await queryRunner.rollbackTransaction();
        }
        reject(e);
      }
    });
  }

  async insertData(data: AppBaseEntity[]): Promise<void> {
    const connection = getConnection();
    const queryRunner = connection.createQueryRunner();
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!connection.isConnected) {
          await queryRunner.connect();
        }
        await queryRunner.startTransaction();
        connection
          .createQueryBuilder(queryRunner)
          .insert()
          .into(this._entityType)
          .values(data)
          .execute();
        resolve();
      } catch (e) {
        if (queryRunner.isTransactionActive) {
          await queryRunner.rollbackTransaction();
        }
        reject(e);
      }
    });
  }

  async bulkInsert(data: AppBaseEntity[] | any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const connection = getConnection();
        const size = Constants.BulkSize;
        if (data instanceof Array) {
          for (let i = 0; i < data.length; i) {
            let batch: AppBaseEntity[];

            if (i + size > data.length) {
              batch = data.slice(i, data.length);
              i = data.length;
            } else {
              batch = data.slice(i, i + size);
              i = i + size;
            }
            await connection
              .createQueryBuilder()
              .insert()
              .into(this._entityType)
              .values(batch)
              .execute();
          }
        } else {
          await connection
            .createQueryBuilder()
            .insert()
            .into(this._entityType)
            .values(data)
            .execute();
        }
        resolve();
      } catch (e) {
        reject('Cannot insert data to database.');
      }
    });
  }

  async bulkInsert2(data: AppBaseEntity[] | any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const connection = getConnection();
        const queryRunner = connection.createQueryRunner();
        let sql = '';
        if (data instanceof Array) {
          const entityArray: any[] = [];
          for (const d of data) {
            const e = new this._entityType(d);
            entityArray.push(e);
          }
          const entityLength = entityArray.length;
          if (entityLength > 0) {
            let entityCountLeft = entityLength;
            do {
              const entityCountToTake = Math.min(
                entityCountLeft,
                Constants.BulkSize
              );
              const sub = entityArray.splice(0, entityCountToTake);
              sql = sub[0].getInsertSqlForEntities(sub);
              await queryRunner.query(sql);
              entityCountLeft -= entityCountToTake;
            } while (entityCountLeft > 0);
          }
        } else {
          const e = new this._entityType(data);
          sql = e.getInsertSqlForEntity();
          await queryRunner.query(sql);
        }
        resolve();
      } catch (e) {
        reject('Cannot insert data to database.');
      }
    });
  }

  async bulkInsertWithQueryRunner(
    data: AppBaseEntity[] | any,
    queryRunner: QueryRunner
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let sql = '';
        if (data instanceof Array) {
          const entityArray: any[] = [];
          for (const d of data) {
            const e = new this._entityType(d);
            entityArray.push(e);
          }
          if (entityArray.length > 0) {
            sql = entityArray[0].getInsertSqlForEntities(entityArray);
            await queryRunner.query(sql);
          }
        } else {
          const e = new this._entityType(data);
          sql = e.getInsertSqlForEntity();
          await queryRunner.query(sql);
        }
        resolve();
      } catch (e) {
        reject('Cannot insert data to database.');
      }
    });
  }

  async insertOrUpdateData(data: BaseEntity[] | any): Promise<void> {
    const connection = getConnection();
    return new Promise<void>(async (resolve, reject) => {
      try {
        const repo = connection.getRepository(this._entityType);

        if (data instanceof Array) {
          const size = 250;
          for (let i = 0; i < data.length; i) {
            let batch: BaseEntity[];

            if (i + size > data.length) {
              batch = data.slice(i, data.length);
              i = data.length;
            } else {
              batch = data.slice(i, i + size);
              i = i + size;
            }
            await repo.save(batch);
          }
        } else {
          await repo.save(data);
        }
        resolve();
      } catch (e) {
        reject('Cannot insert data to database.');
      }
    });
  }

  async insertOrUpdateDataWithQueryRunner(
    data: BaseEntity[] | any,
    queryRunner: QueryRunner
  ): Promise<void> {
    const connection = queryRunner.connection;

    return new Promise<void>(async (resolve, reject) => {
      try {
        const repo = connection.getRepository(this._entityType);

        if (data instanceof Array) {
          const size = 250;
          for (let i = 0; i < data.length; i) {
            let batch: BaseEntity[];

            if (i + size > data.length) {
              batch = data.slice(i, data.length);
              i = data.length;
            } else {
              batch = data.slice(i, i + size);
              i = i + size;
            }
            await repo.save(batch);
          }
        } else {
          await repo.save(data);
        }
        queryRunner.manager.createQueryBuilder();
        resolve();
      } catch (e) {
        reject('Cannot insert data to database.');
      }
    });
  }

  updateEntity(entity: BaseEntity): Promise<BaseEntity> {
    const connection = getConnection();
    return connection.getRepository(this._entityType).save(entity);
  }

  getDeltaData(config: SyncConfig): BaseEntity[] | any {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const connection: Connection = getConnection();
        const manager = connection.createEntityManager();
        let entities: BaseEntity[] = [];
        if (config.lastDownloadTime !== '') {
          const sql =
            'select * from ' +
            this._entityType.name +
            ' where UpdatedDate > :lastUpdatedTime';
          entities = await manager.query(sql, [config.lastDownloadTime]);
        }

        if (entities) {
          resolve(entities);
        } else {
          resolve(null);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /*
    async getOne(): Promise<BaseEntity> {
        return new Promise<BaseEntity>(async (resolve, reject) => {
            let record = await getConnection()
                .createQueryBuilder()
                .select()
                .from(this._entityType, this._entityType.name)
                .orderBy('ObjectId').
                getOne();

            resolve(record);
        });
    }
    */
}
