import { Injectable } from '@angular/core';
import { Hierarchy } from 'src/data/EntityIndex';
import { AppBaseServiceProvider } from './AppBaseService';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService extends AppBaseServiceProvider {
  serviceName = 'Hierarchy';
  flattenedHierarchyArray: Hierarchy[];
  selectedHierarchyPath: Hierarchy[];
  currentVersion = '1.0';
}
