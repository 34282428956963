import { Injectable } from '@angular/core';
import { WindowRef } from 'src/shared/window-ref';
import { Device } from '@ionic-native/device/ngx';
import { AppPlatform } from 'src/data/InternalTypes';
import { ToastController, AlertController } from '@ionic/angular';
@Injectable()
export class Utility {
  constructor(
    private device: Device,
    public winRef: WindowRef,
    private toastController: ToastController,
    private alertController: AlertController
  ) { }

  disableUWPDefaultBack(): Promise<void> {
    return new Promise<void>(resolve => {
      try {
        const devicePlatform = this.device.platform;
        if (
          devicePlatform === AppPlatform.windows ||
          devicePlatform === AppPlatform.core
        ) {
          const currentView = this.winRef.nativeWindow.Windows.UI.Core.SystemNavigationManager.getForCurrentView();
          currentView.appViewBackButtonVisibility = this.winRef.nativeWindow.Windows.UI.Core.AppViewBackButtonVisibility.collapsed;
        }
        resolve();
      } catch (error) {
        resolve();
      }
    });
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: 'dark',
      mode: 'md',
    });
    toast.present();
  }

  async presentAlert(msg: string) {
    const alertController = await this.alertController.create({
      header: 'Info',
      subHeader: '',
      message: msg,
      buttons: [
        {
          text: 'OK',
          handler: () => { },
        },
      ],
    });
    await alertController.present();
  }
}
