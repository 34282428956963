import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private counter: number;
  public loadingTitle = 'loading';
  constructor(private translate: TranslateService) {
    this.counter = 0;
  }

  present() {
    this.loadingTitle = this.translate.instant('SHARED.Loading');
    this.counter++;
  }

  dismiss() {
    if (this.counter > 0) {
      this.counter--;
    }
  }
  dismissAll() {
    setTimeout(() => {
      this.counter = 0;
    }, 100);
  }

  isVisible(): boolean {
    return this.counter > 0;
  }
}
