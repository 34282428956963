import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { ActivationContextProvider } from './activationContext';
import {
  AppPlatform,
  TAProDeeplinkObject,
  initViewType,
  NoteAddMode,
} from 'src/data/InternalTypes';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ToastController, NavController } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { Hierarchy } from 'src/data/EntityIndex';
import { HierarchyEntityManagerService } from 'src/data/EntityManagerIndex';
import { LoadingService } from 'src/services/loading.service';
import { environment } from 'src/environments/environment';
import { ProfileHelperService } from 'src/data/helper/profile-helper.service';
import { Profile } from 'src/data/model/Profile';
@Injectable()
export class DeeplinkProvider {
  private syncEnabledNavExtra: NavigationExtras = {
    state: {
      isSyncEnabled: true,
    },
    replaceUrl: true,
  };

  get schemeName(): string {
    return environment.schemeName;
  }

  private _deeplinkedEnabled = false; // flag indicates app is opened from other app
  public get deeplinkedEnabled() {
    return this._deeplinkedEnabled;
  }
  public set deeplinkedEnabled(value) {
    this._deeplinkedEnabled = value;
  }

  constructor(
    private device: Device,
    private activationContextProvider: ActivationContextProvider,
    private inAppBrowser: InAppBrowser,
    private toastController: ToastController,
    private navController: NavController,
    private deeplinks: Deeplinks,
    private router: Router,
    private hierarchyEntityService: HierarchyEntityManagerService,
    private loadingService: LoadingService,
    private activationContext: ActivationContextProvider,
    private profileHelper: ProfileHelperService
  ) {}

  openExternalApp(params: string) {
    const schemeName: string = this.schemeName;
    const schemeParams: string = params;
    switch (this.device.platform) {
      case AppPlatform.core:
      case AppPlatform.windows:
        this.activationContextProvider.launchExternalApp(
          schemeName,
          schemeParams
        );
        break;
      case AppPlatform.iOS:
        this.inAppBrowser.create(schemeName + schemeParams, '_system');
        break;
      case AppPlatform.android:
        this.inAppBrowser.create(schemeName + schemeParams, '_system');
        break;
    }
  }
  initializeLaunchParams(
    deeplinkParams: TAProDeeplinkObject
  ): Promise<TAProDeeplinkObject> {
    return new Promise<TAProDeeplinkObject>((resolve, reject) => {
      this.loadingService.present();
      this.checkProfile()
        .then((profile) => {
          if (!profile && !deeplinkParams) {
            deeplinkParams = new TAProDeeplinkObject();
            deeplinkParams.initViewtype = initViewType.home;
            resolve(deeplinkParams);
          } else if (profile && deeplinkParams) {
            if (profile.preferredEventId === deeplinkParams.eventId) {
              resolve(deeplinkParams);
            } else {
              const newProfile = profile;
              newProfile.preferredEvent = deeplinkParams.eventName;
              newProfile.preferredEventId = deeplinkParams.eventId;
              this.registerProfile(newProfile).then((_) => {
                resolve(deeplinkParams);
              });
            }
          } else if (profile && !deeplinkParams) {
            const newDeeplinkParams = new TAProDeeplinkObject();
            newDeeplinkParams.initViewtype = initViewType.viewHistory;
            newDeeplinkParams.role = profile.preferredRole;
            newDeeplinkParams.site = profile.preferredOBS;
            newDeeplinkParams.eventId = profile.preferredEventId;
            newDeeplinkParams.eventName = profile.preferredEvent;
            resolve(newDeeplinkParams);
          } else if (!profile && deeplinkParams) {
            const newProfile = new Profile();
            newProfile.preferredRole = deeplinkParams.role;
            newProfile.preferredOBS = deeplinkParams.site;
            newProfile.preferredEventId = deeplinkParams.eventId;
            newProfile.preferredEvent = deeplinkParams.eventName;
            this.registerProfile(newProfile).then((_) =>
              resolve(deeplinkParams)
            );
          }
        })
        .finally(() => {
          this.loadingService.dismiss();
        });
    });
  }
  getDeeplinkParams(): Promise<TAProDeeplinkObject | null> {
    return new Promise<TAProDeeplinkObject | null>((resolve, reject) => {
      if (
        this.device.platform === AppPlatform.iOS ||
        this.device.platform === AppPlatform.android
      ) {
        this.getParamsNonWindows()
          .then((params) => {
            resolve(params);
          })
          .catch((e) => reject(e.message));
      } else if (
        this.device.platform === AppPlatform.windows ||
        this.device.platform === AppPlatform.core
      ) {
        this.getParamsWindows()
          .then((params) => {
            resolve(params);
          })
          .catch((e) => reject(e.message));
      } else {
        resolve(null);
      }
    });
  }
  getParamsNonWindows(): Promise<TAProDeeplinkObject> {
    return new Promise<TAProDeeplinkObject>((resolve, reject) => {
      this.deeplinks
        .routeWithNavController(this.navController, {
          '/viewNotes': 'ViewNotesPage',
          '/addNotes': 'AddNotesPage',
        })
        .subscribe(
          (match) => {
            if (match.$link) {
              const params: TAProDeeplinkObject = new TAProDeeplinkObject();
              params.initViewtype = this._getInitViewType(
                match.$link.path
              );
              const args = JSON.parse(
                '{"' +
                  decodeURI(match.$link.queryString)
                    .replace(/"/g, '\\"')
                    .replace(/&/g, '","')
                    .replace(/=/g, '":"') +
                  '"}'
              );
              params.eventId = parseInt(args.eventId, 0);
              params.provider = args.provider;
              params.hierarchyId = parseInt(args.hierarchyId, 0);
              params.role = args.role;
              params.eventName = args.eventName;
              params.site = args.site;
              this.initializeLaunchParams(params).then((initParams) => {
                this.navigateToInitialPage(initParams);
                resolve(params);
              });
            } else {
              this.initializeLaunchParams(null).then((initParams) => {
                this.navigateToInitialPage(initParams);
                resolve(initParams);
              });
            }
          },
          (nomatch) => {
            this.initializeLaunchParams(null).then((initParams) => {
              this.navigateToInitialPage(initParams);
              resolve(initParams);
            });
          }
        );

      resolve(null);
    });
  }

  getParamsWindows(): Promise<TAProDeeplinkObject> {
    return new Promise<TAProDeeplinkObject>((resolve, reject) => {
      try {
        const activationKind =
          this.activationContext.getActivationContextKind();
        const deeplinkObject = this.activationContext.getActivationContext();
        if (
          activationKind &&
          activationKind === this.activationContext.protocolKind
        ) {
          if (deeplinkObject?.uri?.queryParsed?.size > 0) {
            const queryObj = deeplinkObject.uri.queryParsed;
            const params: TAProDeeplinkObject = new TAProDeeplinkObject();
            params.initViewtype = this._getInitViewType(
              deeplinkObject.uri.path
            );
            for (const key in queryObj) {
              if (
                [
                  'eventId',
                  'eventName',
                  'provider',
                  'site',
                  'role',
                  'hierarchyId',
                ].includes(queryObj[key].name)
              ) {
                params[queryObj[key].name] = queryObj[key].value;
              }
            }
            this.initializeLaunchParams(params).then((initParams) => {
              this.navigateToInitialPage(initParams);
              resolve(params);
            });
          } else {
            this.initializeLaunchParams(null).then((initParams) => {
              this.navigateToInitialPage(initParams);
              resolve(null);
            });
          }
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  private _getInitViewType(path: string
  ): initViewType {
    let initViewtype = initViewType.home;
    if (['/viewNotes/', '/viewNotes', 'viewNotes'].includes(path))
      initViewtype = initViewType.viewNotes;
    else if (['/addNotes/', '/addNotes', 'addNotes'].includes(path))
      initViewtype = initViewType.addNotes;

    return initViewtype;
  }

  registerProfile(params: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.profileHelper
        .initializeProfile()
        .then((profile) => {
          profile.preferredSOR = params.preferredSOR;
          profile.preferredRole = decodeURIComponent(params.preferredRole);
          profile.preferredOBS = params.preferredOBS;
          profile.preferredEventId = params.preferredEventId;
          profile.preferredWBSId = params.preferredWBSId;
          profile.preferredEvent = decodeURIComponent(params.preferredEvent);
          this.profileHelper
            .updateProfile(profile)
            .then((_) => resolve())
            .catch((error) => reject(error));
        })
        .catch((e) => reject(e));
    });
  }
  checkProfile(): Promise<Profile | null> {
    return new Promise<Profile | null>((resolve, reject) => {
      this.profileHelper
        .getProfile()
        .then((ret) => {
          if (ret) {
            resolve(ret);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    });
  }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000,
      position: 'top',
      color: 'dark',
      mode: 'md',
    });
    toast.present();
  }

  getHierarchyForNavigation(
    eventId: number,
    provider: string,
    hierarchyId: number
  ): Promise<Hierarchy> {
    return new Promise<Hierarchy>(async (resolve, reject) => {
      try {
        const hierarchy: Hierarchy =
          await this.hierarchyEntityService.getHierarchyById(
            hierarchyId,
            eventId,
            provider
          );
        resolve(hierarchy);
      } catch (error) {
        reject(error);
      }
    });
  }

  async navigateToInitialPage(params: TAProDeeplinkObject | null) {
    try {
      let pageName: initViewType,
        provider: string,
        eventId: number,
        hierarchyId: number;
      if (params) {
        pageName = params.initViewtype;
        provider = params.provider || 'TaPro';
        eventId = params.eventId || null;
        hierarchyId = params.hierarchyId || null;
      } else {
        pageName = initViewType.home;
      }
      this.loadingService.present();
      switch (pageName) {
        case initViewType.viewHistory:
          this.router
            .navigate(
              ['view-history', provider, eventId],
              this.syncEnabledNavExtra
            )
            .finally(() => {
              this.loadingService.dismiss();
            });
          break;
        case initViewType.viewNotes:
          this.router
            .navigate(
              ['view-notes', provider, eventId, hierarchyId],
              this.syncEnabledNavExtra
            )
            .finally(() => {
              this.loadingService.dismiss();
            });
          break;
        case initViewType.addNotes:
          const navigationExtrasAddNotes: NavigationExtras = {
            state: {
              isSyncEnabled: true,
              mode: NoteAddMode.create,
            },
            replaceUrl: true,
          };
          this.router
            .navigate(
              ['add-notes-id', provider, eventId, hierarchyId],
              navigationExtrasAddNotes
            )
            .finally(() => {
              this.loadingService.dismiss();
            });
          break;
        default:
          this.router.navigate(['home'], { replaceUrl: true }).finally(() => {
            this.loadingService.dismiss();
          });
          break;
      }
      this.deeplinkedEnabled = true;
    } catch (error) {}
  }
}
