import { Injectable } from '@angular/core';
import { Summary } from '../entity/Summary';
import { getRepository, SelectQueryBuilder } from 'typeorm/browser';
import { AppBaseEntityManager } from './AppBaseEntityManager';

@Injectable({
  providedIn: 'root'
})
export class SummaryEntityManagerService extends AppBaseEntityManager {



  protected _entityType = Summary;

  constructor() {
    super();
   }

   saveSummary(summary: Summary[]): Promise<void> {
    return this.bulkInsert2(summary);
  }

  getAllOfflineNotes() : Promise<Summary[]> {
    return new Promise<Summary[]>((resolve, reject) => {
      try {
        const summaryRepository = getRepository('Summary');
        const summaryQueryBuilder: SelectQueryBuilder<Summary> = summaryRepository
          .createQueryBuilder('Summary')
          .where(
            'IsUploaded != 1 and Summary.IsDeleted != 1'
          ) as SelectQueryBuilder<Summary>;
        resolve(summaryQueryBuilder.getMany());
      } catch (error) {
        reject(error);
      }
    });
  }

  getSummary(summry: Summary[]): Promise<Summary[]> {
    return new Promise<Summary[]>(async (resolve, reject) => {
      const summaryRepository = getRepository('Summary');
      const summaryIds = summry.map(({ Id }) => Id).join(`','`);
      const summary = await summaryRepository
        .createQueryBuilder('Summary')
        .where(`Summary.Id IN ('${summaryIds}')`)
        .getMany();
      resolve(summary as Summary[]);
    });
  }

  getOfflineSummary(provider: string,eventId:number):Promise<Summary[]>{
    return new Promise<Summary[]>((resolve, reject) => {
        try{
          const summaryRepository = getRepository('Summary');
          const summaryQueryBuilder: SelectQueryBuilder<Summary> = summaryRepository
          .createQueryBuilder('Summary')
          .where(
            'Summary.EventId = :eventId',
            {
              eventId
            }
          ) as SelectQueryBuilder<Summary>;
        resolve(summaryQueryBuilder.getMany());
        }catch (error) {
        reject(error);
      }
    });
  }

  createSummary(summary: Summary): Promise<void> {
    return new Promise<void>(async (resolve, reject) =>{
      try {
        await summary.save();
        resolve();
      }catch(err){
        reject(err);
      }
    })
  }
}
