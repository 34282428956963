import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/services/loading.service';
import { ProfileHelperService } from 'src/data/helper/profile-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {
  constructor(
    private loadingService: LoadingService,
    private profile: ProfileHelperService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkProfile();
  }
  checkProfile(): Promise<boolean> {
    this.loadingService.present();
    return new Promise<boolean>((resolve, reject) => {
      this.profile
        .getProfile()
        .then(profile => {
          this.loadingService.dismiss();
          if (profile) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(error => {
          this.loadingService.dismiss();
          resolve(true);
        });
    });
  }
}
