import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ADALConfig {
  public readonly tenant: string = 'chevron.onmicrosoft.com';
  public readonly clientId: string = environment.adalConfig.clientId;
  public readonly resource: string = 'https://graph.windows.net';
  public readonly authority: string =
    'https://login.windows.net/chevron.onmicrosoft.com';
  public readonly redirectURL: string = '';

  constructor(
    platform: string,
    packageName: string,
    rawurlencode: string = null
  ) {
    function _window(): any {
      return window;
    }

    switch (platform.toLowerCase()) {
      case 'windows':
        this.redirectURL =
          _window().Windows.Security.Authentication.Web.WebAuthenticationBroker.getCurrentApplicationCallbackUri().rawUri;
        break;
      case 'ios':
        this.redirectURL = 'msauth.com.chevron.tonotestest://auth';
        console.log(this.redirectURL);
        break;
      case 'android':
        this.redirectURL = environment.adalConfig.redirectURL;
        break;
      case 'js':
        this.redirectURL = 'http://localhost:4200';
        break;
      default:
        throw new Error('Invalid Platform Initiated for MSAL library');
    }
  }
}
