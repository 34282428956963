import { Injectable } from '@angular/core';
import { NoteLessonLearnt } from '../entity/LessonLearnt';
import { getRepository } from 'typeorm/browser';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { Notes } from 'src/data/entity/Notes';

@Injectable({
  providedIn: 'root'
})
export class NoteLessonlearntEntityManagerService extends AppBaseEntityManager {

  protected _entityType = NoteLessonLearnt;

  constructor() {
    super();
  }

  getLessonLearntForNote(noteId: string): Promise<NoteLessonLearnt[]> {
    return new Promise<NoteLessonLearnt[]>(async (resolve, reject) => {
      const noteRepository = getRepository('noteLessonLearnt');
      const noteLessonLearnt = await noteRepository
        .createQueryBuilder('noteLessonLearnt')
        .where('noteLessonLearnt.NoteId = :noteId', { noteId })
        .getMany();
      resolve(noteLessonLearnt as NoteLessonLearnt[]);
    });
  }

  getLessonLearntForNotes(notes: Notes[]): Promise<NoteLessonLearnt[]> {
    return new Promise<NoteLessonLearnt[]>(async (resolve, reject) => {
      const noteRepository = getRepository('noteLessonLearnt');
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const noteLessonLearnt = await noteRepository
        .createQueryBuilder('noteLessonLearnt')
        .where(`noteLessonLearnt.NoteId IN ('${notesIds}')`)
        .getMany();
      resolve(noteLessonLearnt as NoteLessonLearnt[]);
    });
  }


  saveLessonLearnt(lessonlearnt: NoteLessonLearnt[]): Promise<void> {
    return this.bulkInsert2(lessonlearnt);
  }

  deletelessonlearntForNote(noteId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('noteLessonLearnt');
        await noteRepository
          .createQueryBuilder()
          .delete()
          .from(NoteLessonLearnt)
          .where('NoteId = :noteId', { noteId })
          .execute();
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  deletelessonlearntForNotes(noteIds: string[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('noteLessonLearnt');
        await noteRepository
          .createQueryBuilder()
          .delete()
          .from(NoteLessonLearnt)
          .where('noteLessonLearnt.NoteId in (:noteIds)', {
            noteIds: noteIds.map(noteId => `'${noteId}'`).join(',')
          })
          .execute();
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

}
