import { Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('noteTag')
export class NoteTag extends AppBaseEntity {
  @PrimaryColumn({ type: 'text' })
  Tag: string;

  @PrimaryColumn({ type: 'text' })
  NoteId: string;
}
