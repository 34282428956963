import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from '../entityService/AppBaseService';
import { HttpParams } from '@angular/common/http';
import { User } from '../User';

@Injectable({
  providedIn: 'root'
})
export class IdentityServiceProvider extends AppBaseServiceProvider {
  serviceName = 'System';
  downloadOperationName = 'identity';
  currentVersion = '1.0';

  getUser(): Promise<User> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('api-version', this.currentVersion);

    return new Promise<User>(async (resolve, reject) => {
      this.http
        .get<User>(
          `${this.baseURL}/${this.serviceName}/${this.downloadOperationName}`,
          {
            params: httpParams
          }
        )
        .subscribe(
          val => {
            resolve(val);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
