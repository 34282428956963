import { Column, Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';
import { NoteComment } from './NoteComment';
import { Attachment } from '../EntityIndex';
import { NoteLessonLearnt } from './LessonLearnt';

@Entity('notes')
export class Notes extends AppBaseEntity {
  @PrimaryColumn({ type: 'text' })
  Id: string;

  @Column({ type: 'bigint' })
  ParentId: number;

  @Column({ type: 'bigint' })
  EventId: number;

  @Column({ type: 'text' })
  ParentEntity: string;

  @Column({ nullable: true, type: 'text' })
  Role: string;

  @Column({ nullable: true, type: 'text' })
  ShiftDate: string;

  @Column({ nullable: true, type: 'text' })
  ShiftName: string;

  @Column({ nullable: true, default: true, type: 'boolean' })
  IsUploaded: boolean;

  Comments: NoteComment[];
  Lessonlearnt: NoteLessonLearnt[];
  Tags: string[];
  Attachments: Attachment[];

}
