import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { ADALProvider } from 'src/shared/adal/adal';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private adalService: ADALProvider, private platform: Platform) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    if (request.url.includes('alive') === false) {
      return next.handle(request).pipe(
        catchError((err) => {
          if (err.status === 401) {
            return from(this.adalService.msalLogin()).pipe(
              switchMap((_) => {
                request = this.addAuthHeader(request);
                return next.handle(request);
              })
            );
          } else {
            return throwError(err);
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const token = this.adalService.getToken();

    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return request;
    }
  }
}
