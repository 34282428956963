import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, LoadingController, AlertController, ModalController } from '@ionic/angular';
import { CameraProvider } from 'src/shared/services/camera.provider';
import { CameraOutput } from 'src/data/model/camera-output.model';
import { File } from '@ionic-native/file/ngx';
import { Attachment } from 'src/data/EntityIndex';
import { ADALProvider } from 'src/shared/adal/adal';
import { getRepository } from 'typeorm';
import { AttachmentHelperService } from 'src/data/helper/attachment-helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageViewerComponent } from '../image-viewer/image-viewer';
import { NetworkService } from 'src/services/network.service';
import { NoteAttachmentEntityManagerService } from 'src/data/EntityManagerIndex';
import { EventService } from 'src/shared/event';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'photo-only',
  templateUrl: 'photo-only.html',
  styleUrls: ['./photo-only.scss'],
})
export class PhotoOnlyComponent implements OnInit {
  @Input() noteId: any;
  @Input() createMode = true;
  @Input() mode: number;
  attachments = new Array<Attachment>();
  private attachmentsToDelete = new Array<Attachment>();
  private loader: any;
  downloading: boolean;
  cameraOn: boolean = false;
  images: { base64: string }[] = [];

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async openCamera() {
    if (this.cameraOn) {
      this.cameraOn = !this.cameraOn;
      this.webcamImage = null;
    } else {
      this.loader = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Loading Camera...',
      });
      await this.loader.present();
      this.cameraOn = !this.cameraOn;
      await this.delay(14000);

      await this.loader.dismiss();
    }
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  confirmImage(): void {
    this._openPhotoEditor(this.webcamImage.imageAsBase64);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  constructor(
    private router: Router,
    private platform: Platform,
    private cameraProvider: CameraProvider,

    private events: EventService,
    public loadingController: LoadingController,
    private adalService: ADALProvider,
    private file: File,
    private attachmentService: AttachmentHelperService,
    private sanitizer: DomSanitizer,
    private alert: AlertController,
    private modal: ModalController,
    private networkService: NetworkService,
    private noteAttachmentEntityManagerService: NoteAttachmentEntityManagerService
  ) {}

  ngOnInit() {
    this.loadAttachments();
  }
  async loadAttachments() {
    // ionic upgrade commit
    this.attachments =
      await this.noteAttachmentEntityManagerService.getAttachmentsForNote(
        this.noteId
      );

    for (const attachment of this.attachments) {
      attachment.FormatedFile = attachment.FileURI
        ? await this.blobToBase64(
            attachment.FileURI.substring(
              0,
              attachment.FileURI.lastIndexOf(attachment.FileName)
            ),
            attachment.FileName
          )
        : null;
    }
  }

  async photo() {
    if (this.platform.is('cordova')) {
      this.cameraProvider
        .takePicture()
        .then((cameraOutput: any) => {
          this._openPhotoEditor(cameraOutput.FileContent);
        })
        .catch((error: any) => {
          console.log('CAMERA ERROR>>>' + error);
        });
    } else {
      // workaround to simulate take picture on camera
      this._openPhotoEditor(this.webcamImage.imageAsDataUrl);
    }
  }

  onFileSelected(event: any) {
    const files = event.target.files;
    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        alert('Please select only image files.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        this._openPhotoEditor(base64);
      };
      reader.readAsDataURL(file);
    }
  }

  async filePick() {
    if (this.platform.is('cordova')) {
      this._cordovaCameraFilePick();
    } else {
      const message = await this.alert.create({
        header: 'Something  went wrong',
        message: 'This feature is only available on mobile',
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await message.present();
      await message.onDidDismiss();
    }
  }

  private _cordovaCameraFilePick(): void {
    const fileSize = 'File Size';
    const unknownFile = 'Uknown File';
    this.cameraProvider
      .filePick()
      .then((imagedata: CameraOutput) => {
        if (this.cameraProvider.os === 'windows') {
          if (imagedata?.FileBlob?.size / 1024 / 1024 > 5) {
            throw fileSize;
          }
          if (
            !imagedata.FileBlob ||
            !imagedata.FileBlob.type ||
            !this._getFileExt(imagedata.FileBlob.type)
          ) {
            throw unknownFile;
          }
        }
        this._openPhotoEditor(imagedata.FileContent);
      })
      .catch(async (error) => {
        if (typeof error === 'string') {
          const message = await this.alert.create({
            header: 'Something went wrong',
            message: error,
            buttons: [
              {
                text: 'Ok',
              },
            ],
          });
          await message.present();
          await message.onDidDismiss();
        }
      });
  }

  async onImageClick(attachment: Attachment) {
    const modal = await this.modal.create({
      component: ImageViewerComponent,
      cssClass: 'hierarchy-modal',
      componentProps: attachment,
    });
    modal.present();
  }

  async removeImage(attachment: Attachment) {
    const attachmentRepository = getRepository(Attachment);
    const index = this.attachments.indexOf(attachment, 0);
    if (attachment.Id === '') {
      this.attachments.splice(index, 1);
      const att = await attachmentRepository.findOne({
        where: { TimeStamp: attachment.TimeStamp },
      });
      if (att) {
        await att.remove();
      }
    } else {
      attachment.IsDeleted = true;
      attachment.IsUploaded = false;
    }
  }

  enablebuttons() {
    return this.attachments.filter((att) => !att.IsDeleted).length === 2;
  }

  hideViewImages(): boolean {
    const attachmentLength = this.attachments.length;
    const downloadedImagesLength = this.attachments.filter(
      (attachment) => attachment.FileURI
    ).length;
    if (this.platform.is('cordova')) {
      return attachmentLength === downloadedImagesLength;
    } else {
      return false;
    }
  }

  private _openPhotoEditor(photo: string) {
    this.events.formRefreshSource$.subscribe(async (saveImage: any) => {
      if (saveImage !== 'cancel') {
        this.webcamImage = null;
        this.cameraOn = false;
        this.loader = await this.loadingController.create({
          cssClass: 'my-custom-class',
          message: 'Saving Picture...',
        });
        await this.loader.present();
        await this.save(saveImage);
      }
    });
    this.cameraProvider.setImage(photo);
    this.router.navigate(['action-image']);
  }

  private async save(photo: string) {
    try {
      const attachment = new Attachment();
      attachment.Id = '';
      attachment.Format = 'image';
      attachment.NoteId = this.noteId;
      attachment.TimeStamp = new Date().getTime();
      attachment.FileName = `note_image_${attachment.TimeStamp}.png`;
      attachment.IsDeleted = false;
      attachment.IsUploaded = false;
      attachment.IsDownloaded = false;

      attachment.UpdatedDate = new Date().toISOString();
      attachment.UpdatedByUPN = this.adalService.getUPN();
      attachment.CreatedByUPN = attachment.UpdatedByUPN;
      attachment.CreatedDate = attachment.UpdatedDate;
      if (this.platform.is('cordova')) {
        this.writeFile(photo, attachment);
      } else {
        const contentType = this.getContentType(photo);
        const DataBlob = this.base64toBlob(photo, contentType);
        attachment.FormatedFile = photo;
        attachment.BlobFile = DataBlob;
        this.attachments.push(attachment);
        this.loader.dismiss();
      }
    } catch (err) {
      console.log('ERROR IN SAVING IMAGE>>>');
      this.loader.dismiss();
    }
  }

  // here is the method is used to write a file in storage
  public async writeFile(base64Data: any, attachment: Attachment) {
    const contentType = this.getContentType(base64Data);
    const DataBlob = this.base64toBlob(base64Data, contentType);
    // here iam mentioned this line this.file.externalRootDirectory is a native pre-defined file path storage. You can change a file path whatever pre-defined method.
    let filePath = '';
    filePath = this.file.dataDirectory + 'attachments/user/';
    this.file
      .checkDir(filePath, attachment.NoteId.toString())
      .then(async () => {
        console.log('folder exists');
        filePath += attachment.NoteId.toString();
        this.saveImageBlobAndAttachmentEntity(
          filePath,
          attachment.FileName,
          DataBlob,
          contentType,
          attachment
        );
      })
      .catch(async () => {
        this.file
          .createDir(filePath, attachment.NoteId.toString(), false)
          .then(() => {
            console.log('dir created');
            filePath += attachment.NoteId.toString();
            this.saveImageBlobAndAttachmentEntity(
              filePath,
              attachment.FileName,
              DataBlob,
              contentType,
              attachment
            );
          })
          .catch(() => {
            console.log('create dir error');
            this.saveImageBlobAndAttachmentEntity(
              filePath,
              attachment.FileName,
              DataBlob,
              contentType,
              attachment
            );
          });
      });
  }

  saveImageBlobAndAttachmentEntity(
    filePath: string,
    fileName: string,
    DataBlob: any,
    contentType: any,
    attachment: Attachment
  ) {
    this.file
      .writeFile(filePath, fileName, DataBlob, contentType)
      .then(async (fileEntry) => {
        console.log('File Writed Successfully', fileEntry);
        attachment.FileURI = fileEntry.nativeURL;
        attachment.FormatedFile = await this.blobToBase64(
          filePath + '/',
          fileEntry.name
        );
        attachment.BlobFile = DataBlob;
        this.attachments.push(attachment);
        if (this.downloading) {
          attachment.IsDownloaded = true;
          await attachment.save();
          this.downloading = false;
        }
        this.loader.dismiss();
      })
      .catch((err) => {
        console.log('Error Occured While Writing File', err);
        this.loader.dismiss();
      });
  }

  async downloadPictures() {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Downloading...',
    });
    this.loader.present();
    // ionic upgrade commit
    // const attachmentRepository = getRepository(Attachment);
    // check if there is internet connection.
    const ping = await this.networkService.pingServer();
    if (ping) {
      this.attachmentService
        .downloadAttachments(this.noteId)
        .then(
          async (attachmentsResult: Array<any>) =>
            await this._onDownloadAttachments(attachmentsResult)
        );
    } else {
      this.loader.dismiss();
      const message = await this.alert.create({
        header: '',
        message:
          'Looks like server is not responding, please check if there is internet connection',
        buttons: [
          {
            text: 'Ok',
          },
        ],
      });
      await message.present();
      await message.onDidDismiss();
    }
  }

  private async _onDownloadAttachments(attachmentsResult: Array<any>) {
    const attachmentRepository =
      this.noteAttachmentEntityManagerService.getRepository();
    this.attachments = [];
    for (const at of attachmentsResult) {
      const attachment: Attachment = await attachmentRepository.findOne({
        where: { Id: at['id'] },
      });
      const photo = `data:image/png;base64, ${at['fileArray']}`;
      if (this.platform.is('cordova')) {
        this.downloading = true;
        await this.writeFile(photo, attachment);
      } else {
        const contentType = this.getContentType(photo);
        const DataBlob = this.base64toBlob(photo, contentType);
        attachment['FormatedFile'] =  `${photo}`;
        attachment['BlobFile'] = DataBlob;
        attachment.IsDownloaded = true;
        this.attachments.push(attachment);
        attachment.save();
      }
    }
    const OfflineAttachments = await attachmentRepository.query(
      `SELECT * FROM attachment where NoteId = "${this.noteId}" AND Id = ''`
    );
    if (this.platform.is('cordova')) {
      for (const attachment of OfflineAttachments) {
        attachment.FormatedFile = attachment.FileURI
          ? await this.blobToBase64(
              attachment.FileURI.substring(
                0,
                attachment.FileURI.lastIndexOf(attachment.FileName)
              ),
              attachment.FileName
            )
          : null;
      }
    }
    this.attachments.push(...OfflineAttachments);
    this.loader.dismiss();
  }

  // here is the method is used to get content type of an bas64 data
  getContentType(base64Data: any) {
    const block = base64Data.split(';');
    const contentType = block[0].split(':')[1];
    return contentType;
  }

  private _getFileExt(fileType) {
    switch (fileType) {
      case 'image/jpeg':
        return '.jpg';
      case 'image/gif':
        return '.gif';
      case 'image/bmp':
        return '.bmp';
      case 'image/tiff':
        return '.tif';
      case 'image/png':
        return '.png';
      case 'application/msword':
        return '.doc';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.docx';
      case 'text/plain':
        return '.txt';
      case 'application/vnd.ms-excel':
        return '.xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx';
      case 'application/pdf':
        return '.pdf';
      case 'application/rtf':
        return '.rtf';
      default:
        return '';
    }
  }
  // here is the method is used to convert base64 data to blob data
  public base64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    const data = b64Data.split(',')[1];
    const byteCharacters = atob(data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
    });
    return blob;
  }

  blobToBase64(path, fileName) {
    return this.file
      .readAsDataURL(path, fileName)
      .then((base64Img) => {
        return base64Img;
      })
      .catch((err: TypeError) => {
        console.log(err.message);
        this.loader.dismiss();
        return '';
      });
  }
}
