import { Injectable } from '@angular/core';
import { WindowRef } from '../../shared/window-ref';
import { AppStorageProvider } from '../appStorage/app-storage';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AlertController, Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { LogLevel } from 'src/data/InternalTypes';

@Injectable()
export class AnalyticsProvider {
  private logLevel: LogLevel;
  private eventTrackFailure = false;
  private appInsights: ApplicationInsights;

  constructor(
    private platform: Platform,
    private window: WindowRef,
    private alertCtrl: AlertController,
    private storage: AppStorageProvider,
    private device: Device
  ) {
    this.platform.ready().then(async () => {
      const log = (await this.storage.GetLocal('logLevel')) as string;
      if (log) {
        this.logLevel = LogLevel[log];
      } else {
        this.storage.SetLocal('logLevel', LogLevel.Error);
        this.logLevel = LogLevel.Error; // defaults log to error
      }
      if (this.platform.is('cordova') && this.window.nativeWindow.cordova.platformId === 'windows') {
        this.loadAppInsights();
      }
    });
  }

  private loadAppInsights() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.aiKey,
        url: 'assets/ApplicationInsightSDK/ai.js',
        disableAjaxTracking: true
      }
    });
    this.appInsights.loadAppInsights();
  }

  setLogLevel(level: LogLevel) {
    console.log('Updated log level - ' + level);
    this.logLevel = level;
  }

  getLogLevel() {
    return this.logLevel;
  }

  trace(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Trace)) {
      if (!params) params = {};
      const list: any = Object.assign(
        {
          LogLevel: LogLevel.Trace,
          message
        },
        params
      );
      this.trackEvent(event, list);
    }
  }

  info(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Info)) {
      if (!params) params = {};
      const list: any = Object.assign(
        {
          LogLevel: LogLevel.Info,
          message
        },
        params
      );
      this.trackEvent(event, list);
    }
  }

  warn(event: string, message: string, params?: any) {
    if (this.canLog(LogLevel.Warn)) {
      if (!params) params = {};
      const list: any = Object.assign(
        {
          LogLevel: LogLevel.Warn,
          message
        },
        params
      );
      this.trackEvent(event, list);
    }
  }

  error(event: string, message: string, params?: any) {
    console.log(`event: ${event}  | message:${message}`);
    if (this.canLog(LogLevel.Error)) {
      if (!params) params = {};
      const list: any = Object.assign({
        LogLevel: LogLevel.Error,
        message
      },
        params
      );
      this.trackEvent(event, list);
    }
  }

  private canLog(level: LogLevel) {
    switch (this.logLevel) {
      case LogLevel.Trace:
        return true;
      case LogLevel.Info:
        if (level !== LogLevel.Trace) {
          return true;
        }
        return false;
      case LogLevel.Warn:
        if (level !== LogLevel.Trace && level !== LogLevel.Info) {
          return true;
        }
        return false;
      case LogLevel.Error:
        if (level === LogLevel.Error) {
          return true;
        }
        return false;
    }
  }

  private trackEvent(event: string, params: any) {
    // If Windows, just console log for now, until we enable the app to use web
    if (this.platform.is('cordova') && this.window.nativeWindow.cordova.platformId === 'windows') {
      params['Platform'] = 'windows';
      console.log(this.getString(params));
      this.appInsights.trackEvent({
        name: event,
        properties: {
          Properties: this.getString(params)  // Doing this since Appcenter following this standard.
        }
      });
    } else if (this.platform.is('cordova') && this.window.nativeWindow.AppCenter !== null) {
      params['Platform'] = this.device.platform.toLowerCase();
      console.log('MTA ---> ' + event + ' , params ---> ' + JSON.stringify(params));
      this.window.nativeWindow.AppCenter.Analytics.trackEvent(event, params, (success) => {
      }, async error => {
        console.log('Track Event Failure --->');
        if (!this.eventTrackFailure) {
          const alert = this.alertCtrl.create({
            header: 'Error',
            subHeader:
              'Error tracking the events, please report the issue.' + JSON.stringify(error),
            buttons: ['OK']
          });
          this.eventTrackFailure = true; // Throw the alert only once, something went wrong with Analytics Cordova plugin.
          (await alert).present();
        }
      }
      );
    } else {
      console.log('MTA ---> ' + event + ' , params ---> ' + JSON.stringify(params));
    }
  }

  private getString(params: any): string {
    const cache = [];
    return JSON.stringify(params, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Duplicate reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
  }

}

