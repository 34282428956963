
import { Attachment } from 'src/data/EntityIndex';
import { NavParams, ModalController } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'image-viewer',
  templateUrl: 'image-viewer.html',
})
export class ImageViewerComponent {
  id: string | number;
  b64: string;

  private attachment: Attachment;

  constructor(private modalController: ModalController, navParams: NavParams) {
    this.attachment = navParams.data as Attachment;
    this.b64 = this.attachment ? this.attachment.FormatedFile : navParams.get('b64');
  }

  close(): void {
    this.modalController.dismiss();
  }
}
