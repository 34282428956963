import { MigrationInterface, QueryRunner } from "typeorm";

export class True1678110664828 implements MigrationInterface {

  public async up(queryRunner: QueryRunner): Promise<any> {
    try {
      await queryRunner.query(`SELECT KazakText FROM 'noteComment'`);
    } catch {
      await queryRunner.query(`ALTER TABLE 'noteComment' ADD 'KazakText' varchar`);
      await queryRunner.query(`ALTER TABLE 'noteComment' ADD 'RussianText' varchar`);
    }
  }

  public async down(queryRunner: QueryRunner): Promise<any> {
    await queryRunner.query(`ALTER TABLE 'noteComment' DROP COLUMN 'KazakText' varchar`);
    await queryRunner.query(`ALTER TABLE 'noteComment' DROP COLUMN 'RussianText' varchar`);
  }

}
