import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { Observable } from 'rxjs';
import { NoticePage } from 'src/pages/notice/notice.page';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NoticeGuard implements CanActivate {
  constructor(private modalCtrl: ModalController) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> {
    return this.checkNotice();
  }
  checkNotice(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const isNoticeAccepted = Boolean(
        JSON.parse(localStorage.getItem('isNoticeAccepted'))
      );
      if (isNoticeAccepted) {
        resolve(true);
      } else {
        const noticeCtrl = await this.modalCtrl.create({
          component: NoticePage,
          cssClass: 'hierarchy-modal',
          backdropDismiss: false,
          showBackdrop: false
        });
        noticeCtrl.onDidDismiss().then(() => {
          resolve(true);
        });
        noticeCtrl.present();
      }
    });
  }
}
