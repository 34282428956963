import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import {
  createConnection,
  Connection,
  ConnectionOptions,
  getConnection
} from 'typeorm/browser';
import { ReplaySubject } from 'rxjs';
import { Constants } from 'src/shared/constants';
import {
  NoteComment,
  NoteLessonLearnt,
  NoteTag,
  Notes,
  Hierarchy,
  AppBaseEntity,
  User,
  Code,
  OBS,
  SyncLog,
  Attachment, Filter,Summary
} from 'src/data/EntityIndex';
import { Event } from 'src/data/model/Event';
import { environment } from 'src/environments/environment';
import { InstanceID1619547875279 } from 'src/data/migration/1619547875279-instanceID';
import { True1678110664828 } from 'src/data/migration/1678110664828-true';


@Injectable({
  providedIn: 'root'
})
export class DbService {
  private connection: ReplaySubject<Connection> = new ReplaySubject<
    Connection
  >();

  private readonly entities: typeof AppBaseEntity[] = [
    Notes,
    Hierarchy,
    NoteComment,
    NoteLessonLearnt,
    NoteTag,
    User,
    SyncLog,
    Code,
    OBS,
    Event,
    Attachment,
    Filter,
    Summary
    // TODO: add more entities
  ];

  constructor(private platform: Platform) {}

  get Connection(): ReplaySubject<Connection> {
    return this.connection;
  }

  set Connection(connection: ReplaySubject<Connection>) {
    this.connection = connection;
  }

  ready(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        // this.platform.ready().then(() => {
        getConnection();
        resolve(true);
        // });
      } catch (ex) {
        this.createConnection()
          .then(ret => {
            localStorage.setItem('isDBExisted', 'true');
            resolve(true);
          })
          .catch(err => {
            resolve(false);
          });
      }
    });
  }

  private createConnection(): Promise<Connection> {
    return new Promise<Connection>((resolve, reject) => {
      let dbOptions: ConnectionOptions;
      let isSychronizeEnabled = false;
      const isDBExisted = localStorage.getItem('isDBExisted');
      if (isDBExisted !== 'true') {
        // If the version is not matching then drop the schema
        isSychronizeEnabled = true;
      }
      if (this.platform.is('cordova')) {
        dbOptions = {
          type: 'cordova',
          database: Constants.DBName,
          location: 'default',
          entities: this.entities
        };
      } else {
        dbOptions = {
          type: 'websql',
          database: Constants.DBName,
          version: Constants.DBVersion,
          description: Constants.DBName,
          size: 2 * 1024 * 1024,
          entities: this.entities,
          synchronize: false
        };
      }
      // additional options
      Object.assign(dbOptions, {
        logging: environment.adtionalDbOptions,
        synchronize: isSychronizeEnabled,
        entities: this.entities,
        migrations: this.getMigrationscripts(),
        migrationsRun: true

      });

      createConnection(dbOptions)
        .then((connection: Connection) => {
          this.connection.next(connection);
          resolve(connection);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async reset(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // this.platform.ready().then(() => {
        const connection = getConnection();
        await connection.dropDatabase();
        await connection.close();
        localStorage.setItem('isDBExisted', 'false');
        this.createConnection();
        resolve();
        // });
      } catch (ex) {
        if (this.platform.is('cordova')) {
          reject(ex);
        } else {
          resolve(ex); // becuase WebSQL drop database is failing. Known TYPEORM issue.
        }
      }
    });
  }

  private getMigrationscripts() {
    return [InstanceID1619547875279, True1678110664828];
  }
}
