import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { LoadingService } from 'src/services/loading.service';
import { getRepository } from 'typeorm/browser';
import { Code } from '../EntityIndex';

@Injectable({
  providedIn: 'root',
})
export class CodeEntityManagerService extends AppBaseEntityManager {
  protected _entityType = Code;

  constructor(private loading: LoadingService) {
    super();
  }

  getCodes(): Promise<Code[]> {
    return new Promise<Code[]>(async (resolve, reject) => {
      const codeRepository = getRepository(Code);
      const codes = await codeRepository.createQueryBuilder('code').getMany();
      resolve(codes);
    });
  }

  saveCodes(codes: Code[]): Promise<void> {
    return this.bulkInsert2(codes);
  }

  getRoles(): Promise<Code[]> {
    return new Promise<Code[]>(async (resolve, reject) => {
      const codeRepository = getRepository(Code);
      const codes = await codeRepository
        .createQueryBuilder('code')
        // tslint:disable-next-line: quotemark
        .where("code.category='Role'")
        .getMany();
      resolve(codes);
    });
  }

  // Retrive only the category tag
  getTags(): Promise<Code[]> {
    return new Promise<Code[]>(async (resolve, reject) => {
      const codeRepository = getRepository(Code);
      const codes = await codeRepository
        .createQueryBuilder('code')
        .where(`code.category = 'Tag'`)
        .getMany();
      resolve(codes);
    });
  }
}
