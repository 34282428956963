import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { User } from '../EntityIndex';
import { getRepository } from 'typeorm/browser';

@Injectable({
  providedIn: 'root'
})
export class UserEntityManagerService extends AppBaseEntityManager {
  protected _entityType = User;

  constructor() {
    super();
  }

  saveUsers(users: User[]): Promise<void> {
    return this.bulkInsert2(users);
  }

  getUser(upn: string): Promise<User> {
    const userRepository = getRepository('user');
    return userRepository
      .createQueryBuilder('user')
      .where('user.UPN = :upn COLLATE NOCASE', {
        upn
      })
      .getOne() as Promise<User>;
  }

  getUserUpnsWithNotes(eventId) {
    const userRepository = getRepository('user');
    return userRepository.query(`
      SELECT user.UPN FROM notes
      left join user on user.UPN == notes.CreatedByUPN
      where notes.EventId == ${eventId}
      group by user.UPN`);


  }
}
