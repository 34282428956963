import { Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('code')
export class Code extends AppBaseEntity {

    @PrimaryColumn({ type: 'text' })
    Category: string;

    @PrimaryColumn({ type: 'text' })
    Value: string;
}
