import { MigrationInterface, QueryRunner } from 'typeorm/browser';

export class InstanceID1619547875279 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        try {
            await queryRunner.query(`SELECT InstanceId FROM 'Obs';`);
        } catch {
            await queryRunner.query(`ALTER TABLE 'Obs' ADD 'InstanceId' bigint;`);
        }
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query(`ALTER TABLE 'Obs' ADD 'InstanceId' integer default 0;`);
    }
}
