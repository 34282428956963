import { Injectable } from '@angular/core';
import { NoteComment } from '../entity/NoteComment';
import { getRepository } from 'typeorm/browser';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { Notes } from 'src/data/entity/Notes';

@Injectable({
  providedIn: 'root'
})
export class NoteCommentEntityManagerService extends AppBaseEntityManager {
  protected _entityType = NoteComment;

  constructor() {
    super();
  }

  getCommentsForNote(noteId: string): Promise<NoteComment[]> {
    return new Promise<NoteComment[]>(async (resolve, reject) => {
      const noteRepository = getRepository('noteComment');
      let noteComments = await noteRepository
        .createQueryBuilder('noteComment')
        .where('noteComment.NoteId = :noteId', { noteId })
        .getMany();
        const language = localStorage.getItem("language");
        if(language === 'kaz'){

          for(let child of noteComments as NoteComment[]){
            child.Text = child.KazakText;
         }
        }
        if(language === 'ru'){
          for(let child of noteComments as NoteComment[]){
            child.Text = child.RussianText;
         }
        }
      resolve(noteComments as NoteComment[]);
    });
  }

  getCommentsForNotes(notes: Notes[]): Promise<NoteComment[]> {
    return new Promise<NoteComment[]>(async (resolve, reject) => {
      const noteRepository = getRepository('noteComment');
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const noteComments = await noteRepository
        .createQueryBuilder('noteComment')
        .where(`noteComment.NoteId IN ('${notesIds}')`)
        .getMany();
        const language = localStorage.getItem("language");
        if(language === 'kaz'){
          for(let child of noteComments as NoteComment[]){
            child.Text = child.KazakText;
         }
        }
        if(language === 'ru'){
          for(let child of noteComments as NoteComment[]){
            child.Text = child.RussianText;
         }
        }
      resolve(noteComments as NoteComment[]);
    });
  }

  saveComments(comments: NoteComment[]): Promise<void> {
    return this.bulkInsert2(comments);
  }

  deleteCommentsForNote(noteId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('noteComment');
        await noteRepository
          .createQueryBuilder()
          .delete()
          .from(NoteComment)
          .where('NoteId = :noteId', { noteId })
          .execute();
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  deleteCommentsForNotes(noteIds: string[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('noteComment');
        await noteRepository
          .createQueryBuilder()
          .delete()
          .from(NoteComment)
          .where('noteComment.NoteId in (:noteIds)', {
            noteIds: noteIds.map(noteId => `'${noteId}'`).join(',')
          })
          .execute();
        resolve();
      } catch (error) {
        reject();
      }
    });
  }
}
