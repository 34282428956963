import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DBGuard } from 'src/guard/DBGuard';
import { SyncGuard } from 'src/guard/SyncGuard';
import { ProfileGuard } from 'src/guard/ProfileGuard';
import { NoticeGuard } from 'src/guard/NoticeGuard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [DBGuard, NoticeGuard],
    loadChildren: () =>
      import('../pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/:provider/:eventId/:id',
    canActivate: [DBGuard],
    loadChildren: () =>
      import('../pages/home/home.module').then(m => m.HomePageModule)
  },

  {
    path: 'view-history/:provider/:eventId',
    canActivate: [SyncGuard, ProfileGuard],
    loadChildren: () => import('../pages/view-history/view-history.module').then(m => m.ViewHistoryPageModule)
    // loadchildren:  '../pages/view-history/view-history.module#ViewHistoryPageModule'
  },
  {
    path: 'view-notes/:provider/:eventId/:id',
    canActivate: [SyncGuard, ProfileGuard],
    loadChildren: () => import('../pages/view-notes/view-notes.module').then(m => m.ViewNotesPageModule)
    // loadChildren: '../pages/view-notes/view-notes.module#ViewNotesPageModule'
  }, 
  {
    path: 'add-notes/:provider/:eventId',
    canActivate: [SyncGuard, ProfileGuard],
    loadChildren: () => import('../pages/add-notes/add-notes.module').then(m => m.AddNotesPageModule)
    // loadChildren: '../pages/add-notes/add-notes.module#AddNotesPageModule'
  },

   // lesson learnt route
   {
   path:'update-lessonlearnt/:provider/:eventId',
   canActivate:[SyncGuard,ProfileGuard],
   loadChildren: () => import('../pages/update-lessonlearnt/update-lessonlearnt.module').then(m => m.UpdateLessonLearntPageModule)
   },

  {
    path: 'add-notes-id/:provider/:eventId/:id',
    canActivate: [SyncGuard, ProfileGuard],
    loadChildren: () => import('../pages/add-notes/add-notes.module').then(m => m.AddNotesPageModule)
    // loadChildren: '../pages/add-notes/add-notes.module#AddNotesPageModule'
  },


  {
    path: 'note-details/:provider/:eventId/:id',
    canActivate: [DBGuard, ProfileGuard],
    loadChildren: () => import('../pages/note-details/note-details.module').then(m => m.NoteDetailsPageModule)
    // loadChildren:
    //   '../pages/note-details/note-details.module#NoteDetailsPageModule'
  },
  {
    path: 'action-image',
    canActivate: [DBGuard, ProfileGuard],
    loadChildren: () => import('../pages/action-image/action-image.module').then(m => m.ActionImagePageModule)
    // loadChildren:
    //   '../pages/action-image/action-image.module#ActionImagePageModule'
  },
  {
    path: 'hierarchy/:provider/:eventId',
    canActivate: [SyncGuard],
    // loadChildren: '../pages/hierarchy/hierarchy.module#HierarchyPageModule'
    loadChildren: () =>
      import('../pages/hierarchy/hierarchy.module').then(
        m => m.HierarchyPageModule
      )
  },
  {
    path: 'events',
    loadChildren: () => import('../pages/events/events.module').then(m => m.EventsPageModule)
    // loadChildren: '../pages/events/events.module#EventsPageModule'
  },
  {
    path: 'notice',
    loadChildren: () =>
      import('../pages/notice/notice.module').then(m => m.NoticePageModule)
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../pages/settings/settings.module').then(m => m.SettingsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      onSameUrlNavigation: 'reload',
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
