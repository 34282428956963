import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';

import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { ComponentsModule } from 'src/components/components.module';
import { TokenInterceptor } from '../inteceptors/token.interceptor';
import { AuthService } from './../../src/services/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HierarchyPageModule } from 'src/pages/hierarchy/hierarchy.module';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { DeeplinkProvider } from 'src/shared/deeplink/deeplink';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Utility } from 'src/shared/utility';
import { IonicSelectableModule } from 'ionic-selectable';
import { NoticePageModule } from 'src/pages/notice/notice.module';
import { SharedModule } from 'src/shared/shared.module';
import { AnalyticsProvider } from 'src/shared/analytics/analytics';
import { MTAErrorHandler } from 'src/shared/MTAErrorHandler';
import { CameraProvider } from 'src/shared/services/camera.provider';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { SharedPlatformProvider } from 'src/shared/platform';

import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log( message );
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({ 
		auth: {
      clientId: environment.adalConfig.clientId,
      authority: environment.adalConfig.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',	
		},
		cache: { 
      cacheLocation: BrowserCacheLocation.LocalStorage, 
			storeAuthStateInCookie: isIE,  // set to true for IE 11. Remove this line to use Angular Universal
		},
		system: { 
			allowRedirectInIframe: true, 
			loggerOptions: { 
				loggerCallback, 
				logLevel: LogLevel.Info, 
				piiLoggingEnabled: false, 
			}, 
		}, 
	}); 
} 

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read', 'user.readAll', environment.scope, 'User.Read', "Read.All"]
	);
	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read', 'user.readAll', environment.scope, 'User.Read', "Read.All"],
		},
		loginFailedRoute: "/login-failed",
	};
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule,
    HierarchyPageModule,
    NoticePageModule,
    IonicSelectableModule,
    SharedModule,
    
    MsalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthService,
    SharedPlatformProvider,
    Device,
    AppVersion,
    HttpClient,
    Network,
    Deeplinks,
    DeeplinkProvider,
    Utility,
    InAppBrowser,
    AnalyticsProvider,
    Camera,
    CameraProvider,
    File,
    AppAvailability,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: MTAErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
		},
    {
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (msall) => {
          return () => {
            return msall.loginWithAD().then((isUserLoggedIn: any) => {
              console.log('isUserLoggedIn'); 
              console.log(isUserLoggedIn);            
            })      
          };      
      },
    multi: true

    },

		MsalService,
		MsalGuard,
		MsalBroadcastService,
  
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
