import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppStorageProvider } from './appStorage/app-storage';
import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { LoadingControllerHandler } from './loading-controller-handler';
import { TranslateModule } from '@ngx-translate/core';
import { Guid } from './GUID';

@NgModule({
  declarations: [],
  imports: [IonicModule],
  providers: [
    AppStorageProvider,
    SecureStorage,
    LoadingControllerHandler,
    Guid
  ],
  exports: [
    TranslateModule
  ]
})
export class SharedModule { }
