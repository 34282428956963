import { Column, Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';
import { Notes } from '../EntityIndex';

@Entity('hierarchy')
export class Hierarchy extends AppBaseEntity {
  @PrimaryColumn({ type: 'bigint' })
  ForeignId: number;

  @Column({ nullable: true, type: 'bigint' })
  ForeignParentId: number;

  @Column({ type: 'bigint' })
  EventId: number;

  @Column({ type: 'text' })
  ForeignEntity: string;

  @Column({ nullable: true, type: 'text' })
  ForeignParentEntity: string;

  @Column({ nullable: true, type: 'text' })
  DisplayId: string;

  @Column({ nullable: true, type: 'text' })
  Name: string;

  @Column({ type: 'text' })
  Provider: string;

  @Column({ type: 'int' })
  Sequence: number;

  @Column({ nullable: true, type: 'text' })
  Category: string;

  @Column({ nullable: true, default: false, type: 'boolean' })
  IsCompleted: boolean;

  @Column({ nullable: true, type: 'text' })
  InstanceName: string;

  isExpanded: boolean;
  isChecked: boolean;
  parent: Hierarchy;
  children: Hierarchy[];
  notes: Notes[];
  isMatched: boolean;
  activityChecked: boolean;
}
