import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DeeplinkProvider } from 'src/shared/deeplink/deeplink';
import { AppPlatform } from 'src/data/InternalTypes';
import { Device } from '@ionic-native/device/ngx';
import { LoadingService } from 'src/services/loading.service';
import { Utility } from 'src/shared/utility';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public get showTAPro() {
    return this.deeplinkProvider.deeplinkedEnabled;
  }
  public set showTAPro(value) {
    this.deeplinkProvider.deeplinkedEnabled = value;
  }
  ngOnInit(): void {
    this.initializeApp();
  }
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private device: Device,
    private deeplinkProvider: DeeplinkProvider,
    private loadingService: LoadingService,
    private utility: Utility,
    private translate: TranslateService
  ) {}

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      const devicePlatform = this.device.platform;
      if (
        devicePlatform === AppPlatform.windows ||
        devicePlatform === AppPlatform.core
      ) {
        this.deeplinkProvider.getParamsWindows().finally(() => {
          console.info("empty method");
        });
      } else if (devicePlatform === AppPlatform.iOS) {
        this.loadingService.present();
        this.deeplinkProvider
          .getParamsNonWindows()
          .catch(err => {
            this.utility.presentToast(err);
            this.loadingService.dismiss();
          })
          .finally(() => {
            this.loadingService.dismiss();
          });
      }
      this.translate.addLangs(['en', 'ru', 'kaz']);
      this.translate.setDefaultLang('en');

      const lang = localStorage.getItem('language');

      if (lang) {
        this.translate.use(lang);
      } else {
        this.translate.use('en');
      }
    });
  }

  openTAPro() {
    this.deeplinkProvider.openExternalApp('');
    this.showTAPro = false;
  }
}
