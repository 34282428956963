import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';
import { CameraOutput } from 'src/data/model/camera-output.model';
import { Device } from '@ionic-native/device/ngx';


@Injectable({
    providedIn: 'root',
})
export class CameraProvider {
    public os: string;
    private imageToEdit;
    constructor(
        private platform: Platform,
        private camera: Camera,
        private device: Device
    ) {
        if (this.platform.is('cordova')) {
            this.os = this.device.platform.toLowerCase();
        }
    }

    setImage(image) {
        this.imageToEdit = image;
    }

    public getImage() {
        return this.imageToEdit;
    }
    /**
     * Method to capture picture
     */
    takePictureNormal(): Promise<CameraOutput> {
        return new Promise<any>((resolve, reject) => {
                let saveToAlbum = false;
                if (this.os === 'android' || this.os === 'ios') {
                    saveToAlbum = true;
                }
                const cameraOptions: CameraOptions = {
                    quality: 30,
                    destinationType: this.camera.DestinationType.DATA_URL,
                    encodingType: this.camera.EncodingType.JPEG,
                    mediaType: this.camera.MediaType.PICTURE,
                    sourceType: this.camera.PictureSourceType.CAMERA,
                    targetHeight: 800,
                    targetWidth: 800,
                    saveToPhotoAlbum: saveToAlbum
                };

                this.camera
                    .getPicture(cameraOptions)
                    .then(
                        imageData => {
                            const cameraOutput = new CameraOutput();
                            cameraOutput.FileContent = imageData;
                            resolve(cameraOutput);
                        },
                        error => {
                            reject(error);
                        }
                    )
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
        });
    }

    async takePicture(): Promise<any> {
        let cameraOutput: any = null;
        return new Promise<any>(async (resolve, reject) => {

            cameraOutput = this.takePictureNormal();
            resolve(cameraOutput);
        });
    }

    /**
     * Method to browse and pick file for windows and ios
     */
    filePick(): Promise<CameraOutput> {
        return new Promise<any>(async (resolve, reject) => {
            let cameraOptions: CameraOptions;
            if (this.platform.is('cordova')) {
                cameraOptions = {
                    destinationType: this.camera.DestinationType.FILE_URI,
                    mediaType: this.camera.MediaType.PICTURE,
                    sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
                };
                if (this.os === 'android' || this.os === 'ios') {
                    cameraOptions = {
                        quality: 50,
                        destinationType: this.camera.DestinationType.DATA_URL,
                        encodingType: this.camera.EncodingType.JPEG,
                        mediaType: this.camera.MediaType.PICTURE,
                        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
                        targetHeight: 800,
                        targetWidth: 800
                    };
                }
                this.camera
                    .getPicture(cameraOptions)
                    .then(
                        async imageData => {
                            if (this.os === 'android' || this.os === 'ios') {
                                const cameraOutput = new CameraOutput();
                                cameraOutput.FileContent = imageData;
                                resolve(cameraOutput);
                            } else if (this.os === 'windows') {
                                const blob: Blob = await fetch(imageData).then(res => {
                                    return res.blob();
                                });
                                const fileReader = new FileReader();
                                fileReader.onloadend = () => {
                                    const cameraOutput = new CameraOutput();
                                    const b64: string = fileReader.result
                                        .toString()
                                        .replace(/^data:.+;base64,/, '');
                                    cameraOutput.FileContent = b64;
                                    cameraOutput.FileBlob = blob;
                                    cameraOutput.FileType = blob.type ? blob.type : '';
                                    resolve(cameraOutput);
                                };
                                fileReader.readAsDataURL(blob);
                            }
                        },
                        err => {
                            reject(err);
                        }
                    )
                    .catch(e => {
                        console.log(e);
                        reject(e);
                    });
            } else {
                reject(new Error('Only cordova project is supported'));
            }
        });
    }
}
