import { Injectable } from '@angular/core';
import { getRepository, Repository } from 'typeorm/browser';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { Notes } from 'src/data/entity/Notes';
import { Attachment } from '../EntityIndex';

@Injectable({
  providedIn: 'root',
})
export class NoteAttachmentEntityManagerService extends AppBaseEntityManager {
  protected _entityType = Attachment;

  constructor() {
    super();
  }

  getAttachmentsForNote(noteId: string): Promise<Attachment[]> {
    return new Promise<Attachment[]>(async (resolve, reject) => {
      const noteRepository = getRepository('attachment');
      const noteComments = await noteRepository
        .createQueryBuilder('attachment')
        .where('attachment.NoteId = :noteId', { noteId })
        .andWhere('attachment.IsDeleted == 0')
        .getMany();
      resolve(noteComments as Attachment[]);
    });
  }

  getRepository(): Repository<Attachment> {
    return getRepository('attachment');
  }
  
  getAttachmentsForNotes(notes: Notes[]): Promise<Attachment[]> {
    return new Promise<Attachment[]>(async (resolve, reject) => {
      const noteRepository = getRepository('attachment');
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const noteComments = await noteRepository
        .createQueryBuilder('attachment')
        .where("attachment.NoteId IN ('" + notesIds + "')")
        .andWhere('attachment.IsDeleted == 0')
        .getMany();
      resolve(noteComments as Attachment[]);
    });
  }

  getAttachmentsForNotesHome(notes: Notes[]): Promise<Attachment[]> {
    return new Promise<Attachment[]>(async (resolve, reject) => {
      const noteRepository = getRepository('attachment');
      const notesIds = notes.map(({ Id }) => Id).join(`','`);
      const noteComments = await noteRepository
        .createQueryBuilder('attachment')
        .where("attachment.NoteId IN ('" + notesIds + "')")
        .getMany();
      resolve(noteComments as Attachment[]);
    });
  }

  saveAttachments(attachments: Attachment[]): Promise<void> {
    const attachmentToSave: Attachment[] = attachments.map(
      (att: Attachment) =>
        ({
          Id: att.Id,
          NoteId: att.NoteId,
          FileName: att.FileName,
          IsDownloaded: att.IsDownloaded,
          IsUploaded: att.IsUploaded,
          TimeStamp: att.TimeStamp,
          Format: att.Format,
          FileURI: att.FileURI,
          UpdatedDate: att.UpdatedDate,
          UpdatedByUPN: att.UpdatedByUPN,
          CreatedByUPN: att.CreatedByUPN,
          CreatedDate: att.CreatedDate,
          IsDeleted: att.IsDeleted,
        } as Attachment)
    );
    return this.bulkInsert2(attachmentToSave);
  }

  deleteAttachmentsForNote(noteId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('attachment');
        const query =
          "DELETE FROM attachment WHERE attachment.NoteId = '" + noteId + "'";
        await noteRepository.query(query);
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  deleteAttachmentsForNotes(noteIds: string[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('attachment');
        const notes = noteIds.map((noteId) => "'" + noteId + "'");
        const query =
          'DELETE FROM attachment WHERE attachment.NoteId in (' +
          notes.join(',') +
          ')';
        await noteRepository.query(query);
        resolve();
      } catch (error) {
        reject();
      }
    });
  }

  deleteAttachmentsForNotesNoLongerAvailable(noteIds: string[]): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const noteRepository = getRepository('attachment');
        const notes = noteIds.map((noteId) => "'" + noteId + "'");
        const query =
          'DELETE FROM attachment WHERE attachment.IsDeleted = 1 AND attachment.IsUploaded = 1 AND attachment.NoteId in (' +
          notes.join(',') +
          ')';
        await noteRepository.query(query);
        resolve();
      } catch (error) {
        reject();
      }
    });
  }
}
