import { Column, Entity, PrimaryColumn } from 'typeorm/browser';
import { AppBaseEntity } from './AppBaseEntity';

@Entity('noteLessonLearnt')
export class NoteLessonLearnt extends AppBaseEntity {
  @PrimaryColumn({ type: 'text' })
  Id: string;

  @Column({ type: 'text' })
  NoteId: string;

  @Column({ nullable: true, type: 'text' })
  LessonLearntComment: string;

  @Column({type:'text'})
  LessonLearned: string;

  @Column({ type:'text'})
  People:string;

  @Column({ type:'text' })
  Safety:string;

  @Column({type:'text'})
  Recognition:string;
}
