import { Injectable } from '@angular/core';
import { SelectQueryBuilder, getConnection } from 'typeorm/browser';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { ADALProvider } from 'src/shared/adal/adal';
import * as moment from 'moment';
import { SyncLog } from '../EntityIndex';

@Injectable({
  providedIn: 'root',
})
export class SynclogEntityManagerService extends AppBaseEntityManager {
  constructor(
    private adalService: ADALProvider
  ) {
    super();
  }

  getLastSyncLogsForEntitiesByEnent(
    provider: string,
    eventId: number,
    entity: string
  ): Promise<SyncLog> {
    const connection = getConnection();
    const logRepository = connection.getRepository('synclog');
    const queryBuilder = logRepository
      .createQueryBuilder('synclog')
      .offset(0)
      .limit(1)
      .where('synclog.Provider = :provider and syncLog.Entity = :entity', {
        provider,
        eventId,
        entity
      }) as SelectQueryBuilder<SyncLog>;
    if (eventId) {
      queryBuilder.andWhere('synclog.EventId = :eventId', {
        provider,
        eventId,
        entity
      });
    }
    return queryBuilder.orderBy('synclog.CreatedDate', 'DESC').getOne();
  }

  createUploadSyncLogForEvent(
    provider: string,
    eventId: number,
    serviceName: string,
    largestValue: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const syncLog = new SyncLog();
      syncLog.Entity = serviceName;
      syncLog.EventId = eventId;
      syncLog.Provider = provider;
      syncLog.CreatedByUPN = this.adalService.getUPN();
      syncLog.CreatedDate = moment().toISOString();
      syncLog.LastDownloadTime = moment(
        new Date(largestValue + 1)
      ).toISOString();
      syncLog.LastUploadTime = '';
      syncLog.save().then(() => {
        resolve();
      })
      .catch(err => console.log(err));
    });
  }
}
