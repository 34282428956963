import { Hierarchy } from './entity/Hierarchy';
import { User } from './entity/User';

export enum AppErrorType {
  COMMON_ERROR = 1,
  MULTIPLE_PROJECTS_ASSIGNED,
  NO_PROJECT_ASSIGNED,
  MULTIPLE_ROLES_ASSIGNED,
  NO_ROLE_ASSIGNED,
  AUTHENTICATION_ERROR,
  NOT_AUTHORIZED_USER,
  DATABASE_ERROR,
  NETWORK_ERROR,
  SERVICE_ERROR,
  COMMON_SYNC_ERROR,
  UNSUPPORTED_APPVERSION
}

export enum AppPlatform {
  iOS = 'iOS',
  windows = 'windows',
  android = 'Android',
  core = 'core',
  js = 'js'
}

/**
 * hierarchy view selection modes
 */
export enum HierarchySelectionMode {
  /**
   * single: only one item can be selected
   */
  single,

  /**
   * multiple: multiple items can be selected
   */
  multiple,

  /**
   * view hierarchy only, user cannot select hierarchy, not notes info available
   */
  viewOnly,

  /**
   * view hierarchy only, user cannot select hierarchy, notes info available
   */
  viewWithNotes
}

/**
 * Note library view mode
 */
export enum NotesViewMode {
  /**
   * flat view
   */
  flat,

  /**
   * hierarchy view
   */
  hierarchy
}

export enum ShiftType {
  day = 'Day',
  night = 'Night'
}

export enum DurationType {
  none = '',
  halfDay = '12 Hrs Ago',
  fullDay = '24 Hrs Ago',
  dateRange = 'Date Range'
}

export enum NoteCommentType {
  generalComment = 'Comment',
  pathForward = 'Path Forward',
  outstandingMaterials = 'Outstanding Materials',
  materialsOrdered = 'Materials Ordered',
  materialsReceived = 'Materials Received',
  lessonLearnt = 'Lesson Learnt'
}

export enum NoteFilterAuthorType {
  me = 'My Notes',
  all = 'All Notes',
  other = 'Other'
}

export class Filter {
  hierarchyCategory: string[];
  hierarchy: Hierarchy[];
  duration: DurationType;
  shiftStartDate: string;
  ShiftStart: string;
  shiftEndDate: string;
  shiftEnd: string;
  shiftNames: ShiftType[];
  createdDateFrom: string;
  createdDateTo: string;
  tags: string[];
  authorType: NoteFilterAuthorType;
  authors: User[];
  roles: string[];
  search: string;
  ifavFilter: string;
}

export enum NoteAddMode {
  create,
  edit
}

export class TAProDeeplinkObject {
  initViewtype: initViewType;
  eventId: number;
  eventName: string;
  provider: string;
  site: string;
  role: string;
  hierarchyId: number;
}

export enum initViewType {
  viewNotes = 'viewNotes',
  addNotes = 'addNotes',
  home = 'home',
  viewHistory = 'viewHistory'
}

export enum LogLevel {
  Trace = 'Trace',
  Info = 'Information',
  Warn = 'Warnings',
  Error = 'Error'
}

export enum MobileVersionStatus {
  current = 'Current',
  compatible = 'Compatible',
  notsupported = 'NotSupported',
}
export class MobileVersion {
  version: string;
  status: MobileVersionStatus;
}
