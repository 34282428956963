import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import {
  ModalController,
  NavParams,
  NavController,
  IonContent,
} from '@ionic/angular';
import { Hierarchy } from 'src/data/EntityIndex';
import { ActivatedRoute, Router } from '@angular/router';
import { HierarchySelectionMode } from 'src/data/InternalTypes';
import { HierarchyHelperService } from 'src/data/helper/hierarchy-helper.service';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.page.html',
  styleUrls: ['./hierarchy.page.scss'],
})
export class HierarchyPage implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;

  eventId: number;
  provider: string;
  onlyActiveHierarchies: boolean;
  title: 'Hierarchy';
  hideDeleted: boolean;
  isFromSavedFilter: boolean;


  // hierarchy view mode, single selection or multi-selection
  mode: HierarchySelectionMode = HierarchySelectionMode.single;

  // multi-selection mode used fields
  selectedHierarchies: Hierarchy[]; // all selected hierarchies
  changedHierarchies: Hierarchy[]; // all selected hierarchies

  // multi-selection mode used fields
  selectedCategories: string[]; // all selected categories

  constructor(
    private modalController: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private navController: NavController,
    private hierarchyHelper: HierarchyHelperService,
    @Optional() private navParams: NavParams
  ) {}


  ngOnInit() {
    if (this.navParams) {
      // for modal controller only
      this.eventId = this.navParams.get('eventId');
      this.provider = this.navParams.get('provider');
      this.mode = this.navParams.get('mode');
      this.selectedHierarchies = this.navParams.get('selectedHierarchies');
      this.selectedCategories = this.navParams.get('selectedCategories');
      this.isFromSavedFilter = this.navParams.get('isFromSavedFilter');


    } else {
      // for router node only
      this.route.queryParams.subscribe((params) => {
        const currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation && currentNavigation.extras.state) {
          this.mode = this.router.getCurrentNavigation().extras.state.mode;
        }
      });

      const paramMap = this.route.snapshot.paramMap;
      if (paramMap) {
        const eId = parseInt(paramMap.get('eventId'), 10);
        if (!isNaN(eId)) {
          this.eventId = eId;
        }

        const pro = paramMap.get('provider');
        if (pro) {
          this.provider = pro;
        }
      }
    }
  }

  ionViewDidEnter() {
    if (!this.hideDeleted) {
      this.content.getScrollElement().then((data) => {
        const posY = this.hierarchyHelper.scrollPosition;
        this.content.scrollToPoint(0, posY);
      });
    }
  }

  ionViewDidLeave() {
    if (!this.hideDeleted) {
      this.content.getScrollElement().then((data) => {
        this.hierarchyHelper.scrollPosition = data.scrollTop;
      });
    }
  }

  async done() {
    this.selectedHierarchies = this.changedHierarchies;
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss(this.selectedHierarchies);
    } else {
      if (this.navController) {
        this.navController.pop();
      }
    }
  }

  async cancel() {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss(null);
    } else {
      if (this.navController) {
        this.navController.pop();
      }
    }
  }

  selectHierarchy(hierarchies: Hierarchy[]) {
    this.changedHierarchies = hierarchies;
    if (this.mode === HierarchySelectionMode.single) {
      this.done();
    }
  }

}
