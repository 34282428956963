import { Injectable } from '@angular/core';
import { AppBaseServiceProvider } from '../entityService/AppBaseService';
import { HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { Event } from './../../data/model/Event';

@Injectable({
  providedIn: 'root'
})
export class EventServiceProvider extends AppBaseServiceProvider {
  serviceName = 'Hierarchy';
  downloadOperationName = 'event';
  currentVersion = '1.0';

  getEventsBySite(site: string): Promise<Event[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('api-version', this.currentVersion)
      .append('obs', site);
    console.log('hierarchy obs params', httpParams);
    return new Promise<any[]>(async (resolve, reject) => {
      this.http
        .get<any[]>(`${this.baseURL}/${this.serviceName}`, {
          params: httpParams
        })
        .pipe(timeout(30000))
        .subscribe(
          val => {
            resolve(val);
          },
          error => {
            reject(error);
          },
        );
    });
  }
}
